﻿// sass-lint:disable no-attribute-selectors

@import "../vars/colors";
@import "../vars/fonts";

.form-control {
  height: 34px;
  border: 1px solid #ccc;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

textarea.form-control {
  height: auto;
}

input {
  &[type="button"],
  &[type="submit"] {
    cursor: pointer;
    border: none;
    overflow: visible;
    line-height: normal;
    height: auto;
  }

  &[type="radio"],
  &[type="checkbox"] {
    margin: -1px 5px 0 0;
    padding: 0;
    vertical-align: middle;
    height: 13px;
    width: 13px;
  }

  &[type="text"],
  &[type="password"],
  select,
  textarea {
    border: 1px $color-tide solid;
    // padding: 8px 12px;
    background-color: $color-white;
  }
}

.ng-select .ng-select-container {
  border: 1px $color-tide solid;
}

input,
select,
textarea,
button {
  font-size: $font-14;
  margin: 0;
}

.btn-radio {
  > input {
    &[type="radio"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }
}
