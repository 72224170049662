﻿@import "../../vars/colors";
@import "../../vars/fonts";

//table-bordered
.table-bordered {
    a {
        text-decoration: none;
        outline: none;
    }
}

//table-no-border
.table-no-border {
    td,
    th {
        border: none;
        padding-bottom: 0;
        padding-right: 0;
        padding-top: 4Px;
    }
}
