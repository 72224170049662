﻿@import "media-queries";

@mixin mobile-x-small() {
    @media #{$mobile-x-small} {
        @content;
    }
}

@mixin mobile-small() {
    @media #{$mobile-small} {
        @content;
    }
}

@mixin mobile() {
    @media #{$mobile} {
        @content;
    }
}

@mixin mobile-all-range() {
    @media #{$mobile-all-range} {
        @content;
    }
}

@mixin mobile-all() {
    @media #{$mobile-all} {
        @content;
    }
}

@mixin mobile-upto-640() {
    @media #{$mobile-upto-640} {
        @content;
    }
}


@mixin mobile-all-large() {
    @media #{$mobile-all-large} {
        @content;
    }
}

@mixin mobile-641-768() {
    @media #{$mobile-641-768} {
        @content;
    }
}

@mixin mobile-portrait-320-700() {
    @media #{$mobile-portrait-320-700} {
        @content;
    }
}

@mixin tablet() {
    @media #{$tablet} {
        @content;
    }
}

@mixin mobi-tab() {
    @media #{$mobi-tab} {
        @content;
    }
}

@mixin desktop-small() {
    @media #{$desktop-small} {
        @content;
    }
}

@mixin desktop-small-all() {
    @media #{$desktop-small-all} {
        @content;
    }
}

@mixin desktop() {
    @media #{$desktop} {
        @content;
    }
}

@mixin desktop-large() {
    @media #{$desktop-large} {
        @content;
    }
}


@mixin  mobile-768-1000 {
    @media #{$mobile-768-1000} {
        @content;
    }
}


@mixin  mobile-tablet-768-1125 {
    @media #{$mobile-tablet-768-1125} {
        @content;
    }
}
