﻿//common fonts
$font-xx-small: 6px;
$font-x-small: 9px;
$font-small: 11px;
$font-x-normal: 13px;
$font-normal: 14px;
$font-medium: 16px;
$font-menu: 18px;
$font-title: 20px;
$font-legend: 21px;
$font-x-large: 24px;
$font-xx-large: 27px;

//calculator fonts
$font-cal-desktop: 25px;
$font-cal-icon: 30px;
$font-cal-keypad: 32px;
$font-cal-comment: 50px;
$font-cal-amount: 60px;

//other fonts
$font-7: 7px;
$font-10: 10px;
$font-11: 11px;
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-17: 17px;
$font-18: 18px;
$font-18-5: 18.5px;
$font-19: 19px;
$font-20: 20px;
$font-21: 21px;
$font-22: 22px;
$font-23: 23px;
$font-25: 25px;
$font-28: 28px;
$font-33: 33px;
$font-40: 40px;
$font-50: 50px;
$font-58: 58px;
$font-75: 75px;
$font-80: 80px;
$font-90: 90px;
$font-98: 98px;

//font n-times
$font-3-times: 300%;

//font vh
$font-2-5vh: 2.5vh;
$font-3vh: 3vh;
$font-4vh: 4vh;
$font-6vh: 6vh;

//font em
$font-0-8-5em: 0.85em;
$font-0-9em: 0.9em;
$font-1em: 1em;
$font-1-15em: 1.15em;
$font-2em: 2em;
$font-01em: 1.1em;
$font-02em: 1.2em;
$font-03em: 1.3em;
$font-04em: 1.4em;
$font-05em: 1.5em;
$font-06em: 1.6em;
$font-07em: 1.7em;
$font-17em: 1.17em;
$font-2-6em: 2.6em;
$font-3em: 3em;
$font-7em: 7em;
$font-15em: 15em;

//font rem
$font-0-8rem: 0.8rem;
$font-1-1rem: 1.1rem;
$font-1-3rem: 1.3rem;
