﻿@import "../../shared/colors";
@import "../../vars/fonts";
@import "../../shared/mixins";

.modal-body {
    overflow: visible;

    &__label {
        margin-bottom: 0;
        padding-top: 7px;
    }
}

.modal-content {
    width: inherit;
    height: inherit;
    margin: 0 auto;
    pointer-events: all;
    overflow: hidden;
    border: none;

    &__total {
        font-size: $font-medium;
        color: $theme-color-separator;
    }

    &__process {
        color: $theme-color-primary;
        font-size: $font-title;
        text-align: center;
    }


    &__heading {
        color: $theme-font-color-primary;
        font-size: $font-title;
        font-weight: bold;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        background: $theme-color-primary;
        border-radius: 5px 5px 0 0;
    }

    &__section {
        background: $color-white none repeat scroll 0 0;
        border: 1px solid $color-grey-light;
        margin-bottom: 10px;
        padding: 0 15px 10px;
    }

    &__list {
        height: 400px;
        overflow: auto;
        background: $color-white;
    }

    &__search {
        margin-left: 10px;
        margin-bottom: 10px;
        width: 80%;
    }

    &--green {
        color: $color-green;
        font-size: $font-title;
        text-align: center;
    }

    &__message {
        &--success {
            @include mobile-all-large() {
                line-height: 29px;
            }

            color: $color-green;
            font-size: $font-title;
            text-align: center;
        }
    }

    &__close {
        padding-right: 10px;
        line-height: 1.16;
    }
}

.modal-message {
    color: $color-black;
    font-size: $font-normal;
    padding: 0 0 5px;
    text-align: center;
}

.modal-footer {
    clear: both;
    overflow: hidden;
    margin: unset;
}

.keyboard-modal {
    z-index: 1051;

    &__keyboard {
        width: 95%;

        &__comment {
            @media ( max-width: 775px ) {
                font-size: $font-title;
                min-height: 10px;
            }

            text-align: left;
            min-height: 75px;
            font-size: $font-cal-comment;
            color: $color-black;
            font-weight: bold;
            width: 100%;

            &--area {
                width: 100%;
                font-size: $font-medium;
            }
        }

        &__dynamic-input {
            @media ( max-width: 775px ) {
                font-size: $font-title;
                min-height: 19px;
            }

            text-align: left;
            height: 85px;
            font-size: $font-cal-comment;
            color: $color-black;
            font-weight: bold;
            width: 100%;
        }

        &__btn-img {
            width: 35%;
            float: left;

            &__icon {
                font-size: $font-cal-icon;
            }

            &__block {
                font-size: $font-normal;
            }
        }
    }
}

.numpad-modal {
    &__input {
        @media (max-width: 540px) {
            font-size: $font-40;
            min-height: 65px;
        }

        @include desktop() {
            min-height: 76px;
            font-size: $font-cal-amount;
        }

        @include desktop-large() {
            font-size: $font-90;
            min-height: 140px;
        }

        text-align: right;
        min-height: 90px;
        font-size: $font-cal-amount;
        color: $color-black;
        font-weight: bold;
    }
}


.manual-amount-modal {
    .btn-cal {

        @include mobile-all-large() {
            font-size: $font-x-normal;
            text-align: center;
            padding: 20px 0;
        }

        @media (max-width: 910px) {
            font-size: $font-12;
            text-align: center;
            padding: 20px 0;
        }


        @media (max-width: 415px) {
            font-size: $font-x-normal;
            text-align: center;
            padding: 20px 0;
        }

        @media (max-width: 400px) {
            font-size: $font-x-small;
            text-align: center;
            padding: 20px 0;
        }

        @include mobile-portrait-320-700() {
            font-size: $font-xx-small;
            text-align: center;
            padding: 20px 0;
        }
    }
}

.payment-modal {
    .btn-cal {

        @include mobile-all-large() {
            font-size: $font-medium;
            padding: 14px;
        }

        @include mobile-all-range() {
            font-size: $font-normal;
            padding: 14px 0;
        }

        @media all and (max-width: 479px) {
            font-size: $font-x-normal;
            padding: 15px 0;
            text-align: center;
        }
    }
}

.refund-payment-modal {
    .btn-cal {

        @include mobile-all-large() {
            font-size: $font-medium;
        }
    }
}

.cash-drawer-modal {
    .btn-cal {
        @include mobile-all() {
            padding: 5px 0;
            font-size: $font-normal;
        }
    }
}


.site-modal {
    .modal-content__heading {
        font-size: $font-medium;
        padding-bottom: 8px;
        padding-top: 8px;
    }

    @media (min-width: 767px) {
        .modal-dialog {
            width: 420px;
        }
    }

    .modal-footer {
        padding: 5px 15px 8px;
        min-height: 70px;
    }
}

.time-entry-modal {
    .modal-dialog {
        max-width: 80%;
        width: 90%;
    }

    &__password {
        text-align: center;
        height: 70px;
        font-size: $font-xx-large;
    }
}

.shift-duty-modal {
    .modal-dialog {
        width: 80%;
        margin: 0 auto;
    }

    .shift-duty {
        overflow-x: hidden;
        max-height: 420px;
        margin-left: 6px;

        &__content {
            margin-bottom: 3px;
            margin-top: 8px;



            &__img-block {
                background-color: $color-lemonchiffon;
                border: 2px solid $color-silver;
                cursor: pointer;
                padding: 6px 12px;

                &__img {
                    padding: 4px;
                    height: 25px;
                    width: 25px;
                    margin-right: 6px;
                }
            }

            &__sign-off {
                padding: 6px 12px;
                float: left;
            }
        }
    }
}

.role-assignment-modal {
    .modal-dialog {
        width: 80%;
    }
}

.button-rearrange-modal {
    .modal-dialog {
        max-width: 70%;
        width: 75%;
        left: 0;
        right: 0;
    }
}

.gift-card-balance-modal {
    z-index: 10002;
}

.modal-dashboard-screen-scroll {
    overflow: auto;

    @media ( max-height: 1550px ) {
        height: 1251px;
    }

    @media ( max-height: 1200px) {
        height: 850px;
    }

    @media (min-width: 1900px) {
        height: 850px;
    }

    @media ( max-height: 1090px ) {
        height: 930px;
    }

    @media ( max-height: 960px ) {
        height: 895px;
    }

    @media ( max-height: 900px ) {
        height: 792px;
    }

    @media ( max-height: 850px ) {
        height: 760px;
    }

    @media ( max-height: 800px ) {
        height: 718px;
    }

    @media ( max-height: 775px ) {
        height: 740px;
    }

    @media ( max-height: 750px ) {
        height: 701px;
    }

    @media ( max-height: 725px ) {
        height: 678px;
    }

    @media ( max-height: 700px ) {
        height: 600px;
    }

    @media ( max-height: 675px ) and (max-width: 550px) {
        height: 580px;
    }

    @media ( max-height: 650px ) {
        height: 560px;
    }

    @media ( max-height: 625px ) {
        height: 540px;
    }

    @media ( max-height: 600px ) {
        height: 520px;
    }

    @media ( max-height: 575px ) {
        height: 500px;
    }

    @media ( max-height: 550px ) {
        height: 480px;
    }

    @media ( max-height: 500px ) {
        height: 450px;
    }

    @media ( max-height: 450px ) {
        height: 408px;
    }

    @media ( max-height: 425px ) {
        height: 381px;
    }

    @media ( max-height: 400px ) {
        height: 350px;
    }

    @media ( max-height: 375px ) {
        height: 329px;
    }

    @media ( max-height: 350px ) {
        height: 289px;
    }
}

.user-work-schedule-modal {
    .modal-lg {
        width: 99%;        
    }
    
    .modal-dialog {
        margin: 0;
        top: 50%;
        position: absolute;
        left: 50%;
        // sass-lint:disable no-important
        transform: translate(-50%, -50%) !important;
    }
}
