@import "shared/mixins";
@import "shared/colors";
@import "vars/fonts";
@import "vars/font-family";

@import "components/form-elements";
@import "components/form-elements-resets";
@import "components/navbar";
@import "pages/partials/main-header-entry";
@import "pages/partials/loader";
@import "pages/partials/tables";
@import "pages/partials/modal";
@import "pages/partials/message";
@import "pages/partials/edit-form";
@import "pages/partials/pagination";
@import "pages/dashboard-configuration/widget-add-modal";
@import "components/buttons";
@import "shared/fonts";
@import "shared/colors";
@import "shared/spacing";
@import "shared/pos-utilities";
@import "~codemirror/lib/codemirror.css";

$popover-heading-color: #23527c;

html {
  font-size: $font-14;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

form .form-control::-webkit-input-placeholder {
  color: #999999;
}

form .form-control::-moz-placeholder {
  color: #999999;
}
form .form-control:-ms-input-placeholder {
  color: #999999;
}
form .form-control:placeholder {
  color: #999999;
}

.pos-button {
  border-radius: var(--button-radius, 5px);
}

.btn-group label {
  margin-bottom: 0px;
}

.input-group {
  flex-grow: 1;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  color: $color-mine-shaft;
  font-size: $font-normal;
  font-family: $family-open-sans;
  background-color: $color-alabaster;
  user-select: none;
  --bs-body-line-height: 1.4285;
}

.modal {
  --bs-modal-zindex: 1048;
  --bs-modal-width: 600px;
  @media (max-width: 768px) {
    --bs-modal-width: 96%;
  }
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: unset;
  cursor: no-drop;
}

.modal-footer > * {
  margin: unset;
}

.no-border {
  border: none;
}

.no-wrap {
  white-space: nowrap;
}

.clear-both {
  clear: both;
}

.move {
  cursor: move;
}

.pointer {
  cursor: pointer;
}

.no-cursor {
  cursor: not-allowed;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-visible {
  overflow: visible !important;
}

.height-auto {
  height: auto;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.bold-700 {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.normal {
  font-weight: normal;
}

.vertical-center {
  display: table-cell;
  vertical-align: middle;
  pointer-events: none;
}

.item-vertical-center {
  display: flex;
  align-items: stretch;
}

.vertical-center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-mid {
  vertical-align: middle;
}

.vertical-mid-imp {
  vertical-align: middle !important;
}

.vertical-top {
  vertical-align: top;
}

.vertical-bottom {
  vertical-align: bottom;
}

.alignment-helper {
  display: table;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.align-vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inline {
  display: inline;
}

.display-grid {
  display: grid;
}

.inline-block {
  display: inline-block;
}

.border-1 {
  border: solid 1px $color-grey-light;
}

.border-red {
  border: solid 1px $color-red;
}

.border-active {
  border: solid 1px $color-blue;
}

.border-top {
  border-top: solid 1px $color-grey-light;
}

.border-bottom {
  border-bottom: solid 1px $color-grey-light;
}

.border-left {
  border-left: solid 1px $color-grey-light;
}

.border-right {
  border-right: solid 1px $color-grey-light;
}

.border-lr {
  border-left: solid 1px $color-grey-light;
  border-right: solid 1px $color-grey-light;
}

.border-lrt {
  border-left: solid 1px $color-grey-light;
  border-right: solid 1px $color-grey-light;
  border-top: solid 1px $color-grey-light;
}

.border-lrb {
  border-left: solid 1px $color-grey-light;
  border-right: solid 1px $color-grey-light;
  border-bottom: solid 1px $color-grey-light;
}

.border-bottom-dashed-black {
  border-bottom: dashed 1px $color-black;
}

//text transform
.proper-case {
  text-transform: capitalize;
}

//radius
.radius-0 {
  border-radius: 0;
}

.radius-25 {
  border-radius: 25px;
}

.radius-15 {
  border-radius: 15px;
}

.radius-10 {
  border-radius: 10px;
}

.radius-5 {
  border-radius: 5px;
}

//page section
.page {
  &__title {
    font-size: $font-title;
    margin: 13px 16px 16px;
  }
}

.alert {
  .inherit-position {
    .condensed {
      position: inherit;
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 10px;
      padding: 10px;
      text-align: left;
      width: 100%;
    }
  }
}

//pager page content
.page-content {
  &__header {
    color: $color-grey;
    font-weight: bold;
  }

  &__header-icon {
    > fa-icon {
      font-size: $font-20;
    }
  }

  &__search {
    position: relative;
    // padding-top: 5px;
    margin: 5px 0 10px;
    max-width: 145px;

    i,
    pos-icon {
      position: absolute;
      right: 0;
    }

    i {
      padding: 10px 12px;
    }

    pos-icon {
      padding: 7px 12px;
    }
  }
}

.screen-center {
  position: fixed;
  top: 50%;
  left: 46%;
  margin-top: -50px;
  margin-left: -50px;
}

.search-icons {
  pos-icon {
    position: absolute;
    padding: 5px 10px;
    right: 0;

    fa-icon .fa-times {
      margin-top: 6px;
    }
  }
}

.action-img-default {
  height: 20px;
  width: 20px;
}

.nav-link {
  cursor: pointer;
}

.anchor {
  color: $color-blue;
  text-decoration: underline;
  cursor: pointer;
}

.no-decoration {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}

.bold-normal {
  font-weight: normal;
}

.white-hover {
  color: $color-white;

  &:focus,
  &:hover {
    color: $color-white;
  }
}

.ngdialog-content {
  // sass-lint:disable no-important
  background-color: $color-white !important;

  @media only screen and (max-width: 768px) {
    width: 90% !important;
  }

  //sass-lint:enable no-important
}

.arrow-left {
  //border-right: 1px solid $color-orange-dark;
  padding-top: 15px;
  padding-bottom: 15px;
}

.arrow-right {
  //border-left: 1px solid $color-orange-dark;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 53px;
}

.web-only {
  @include mobile-all-large() {
    display: none;
  }

  display: block;
}

.mobile-only {
  @include mobile-all-large() {
    display: block;
  }

  display: none;
}

.fixed-bottom-section {
  position: fixed;
  left: 0;
  bottom: 0;
  background: $color-wild-sand;
  width: 100%;
  padding-bottom: 10px;
  z-index: 10;
}

.close {
  font-size: $font-40;
  float: right;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;

  &--small {
    line-height: 0.5;
  }
}

// font sizes for header tags

h1 {
  font-size: $font-xx-large;
}

h2 {
  font-size: $font-legend;
}

h3 {
  font-size: $font-17;
}

h4 {
  font-size: $font-normal;
}

h5 {
  font-size: $font-12;
}

.tab-pane {
  h3 {
    padding: 10px 0;
  }
}

.container {
  margin: 0 auto;
  position: relative;
  width: 1003px;
  max-width: 100%;

  &::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    visibility: hidden;
  }
}

.warning {
  color: $color-gold;

  &:focus,
  &:hover {
    color: $color-gold;
  }
}

//validation
.validation-error {
  color: $color-maroon;
  font-weight: bold;
  background-color: $color-fair-pink;
  border-color: $color-red;
}

.required {
  &::after {
    content: "*";
    color: $color-red;
  }
}

.img-responsive {
  max-height: 48px;
}

header {
  background-color: $theme-color-primary;
  color: $theme-font-color-primary;
}

a,
a:not([href]):not([class]) {
  color: $color-orange-extreme;
  text-decoration: none;
  outline: none;
}

a.link:hover {
  color: #23527c;
  text-decoration: underline;
}

.popover-header {
  // sass-lint:disable no-important
  margin-top: -1rem;
  font-size: $font-15 !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  // sass-lint:enable no-important
}

.modal-content {
  .close {
    margin: 2.5px 1px 0 0;
    color: $theme-font-color-primary;
    opacity: 0.5;
    background-color: unset;
  }

  --bs-modal-box-shadow: 0 5px 15px #00000080;
  border-radius: 15px;
  flex-grow: 1;
  box-shadow: var(--bs-modal-box-shadow);

  &__heading {
    border-top-right-radius: 13px;
    border-top-left-radius: 13px;
  }
}

.fixed-popup-bottom {
  position: fixed;
  bottom: 5px;
  width: 60%;
}

.instruction-terminal {
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon-bg {
    width: 25px;
    height: 25px;
    background-color: $color-green;
    border-radius: 15px;

    &__icon {
      padding-top: 6px;
      color: $color-white;
    }
  }
}

.ngdialog {
  &.ngdialog-theme-default {
    padding-bottom: 0;
  }
}

// input {
//   // sass-lint:disable no-attribute-selectors
//   &[type="file"] {
//     height: 34px;
//   }
// }

.screen-block {
  margin-bottom: 65px;
  padding-top: 15px;

  &__filters {
    @media (min-width: 991px) {
      position: fixed;
      left: 0;
      top: 0;
      margin-top: 80px;
      width: 100%;
      z-index: 10;
      background: $color-wild-sand;
    }

    padding-bottom: 10px;

    &__control {
      &--select {
        border: 1px solid $color-grey-light;
        border-radius: 4px;
        height: 40px;
      }

      .dropdown-menu > li > a {
        font-size: $font-menu;
      }

      select {
        option {
          font-size: $font-menu;
        }
      }
    }
  }

  &__items {
    margin-top: 46px;

    &__alert {
      @media (min-width: 991px) {
        margin-top: 80px;
      }

      padding-bottom: 10px;
    }

    &__title {
      margin-top: 40px;
      padding: 25px 0 5px;
      float: left;
      font-weight: bold;
      border-bottom: 1px solid $color-grey;
    }

    &__qty {
      width: 78px;
      float: left;
    }

    &__description {
      width: 50%;
      float: left;
      margin-left: 10px;
      height: 48px;
      vertical-align: middle;
      padding: 9px;

      &--detail {
        font-size: $font-12;
        font-style: italic;
        margin-left: 5px;
      }
    }

    &__bottom-line {
      @include mobile-all-large() {
        width: 100%;
        float: left;
        margin-right: 0;
      }

      border-bottom: 1px solid;
      padding-bottom: 10px;
      border-bottom-color: $color-grey-light;
      margin-right: 12px;
      padding-right: 0;
      width: 49%;
    }
  }
}

.active-price-section {
  background-color: $color-cyan;
}

.keyboard-button {
  color: $color-grey-dark;

  &:hover {
    color: $popover-heading-color;
  }
}

.debugger-button {
  padding-bottom: 1px;
  padding-top: 0;
  margin-top: -2px;
  height: 31px;
}

.header-footer-fixed {
  // sass-lint:disable no-important
  height: 440px !important;
  overflow-y: auto;
  // sass-lint:enable no-important
}

.div-disabled {
  // sass-lint:disable no-important
  cursor: not-allowed;
  background-color: $color-grey-light-0 !important;
  color: $color-grey-light;
  // sass-lint:enable no-important
}

.disabled {
  // sass-lint:disable no-important
  cursor: not-allowed;
  background-color: $color-grey-light-0 !important;
  color: $color-grey-light;
  pointer-events: none;
  // sass-lint:enable no-important
}

.left-nav-toggle {
  opacity: 0.5;
  background: $color-grey-light;
  border-radius: 0 20px 20px 0;
  display: block;
  height: 40px;
  position: fixed;
  left: 0;
  text-align: center;
  width: 45px;
  margin-top: 6px;
  color: $color-white;
  z-index: 9;
  padding-top: 4px;
  font-size: $font-22;
  cursor: pointer;

  &__icon {
    color: $color-white;
  }
}

.full-height {
  height: 100%;
}

.screen-scroll {
  @media (max-height: 1550px) {
    max-height: 1171px;
    overflow: auto;
  }

  @media (max-height: 1200px) {
    max-height: 770px;
    overflow: auto;
  }

  @media (min-width: 1900px) {
    max-height: 770px;
    overflow: auto;
  }

  @media (max-height: 1090px) {
    max-height: 830px;
    overflow: auto;
  }

  @media (max-height: 960px) {
    max-height: 815px;
    overflow: auto;
  }

  @media (max-height: 900px) {
    max-height: 712px;
    overflow: auto;
  }

  @media (max-height: 850px) {
    max-height: 680px;
    overflow: auto;
  }

  @media (max-height: 800px) {
    max-height: 638px;
    overflow: auto;
  }

  @media (max-height: 775px) {
    max-height: 640px;
    overflow: auto;
  }

  @media (max-height: 750px) {
    max-height: 580px;
    overflow: auto;
  }

  @media (max-height: 725px) {
    max-height: 598px;
    overflow: auto;
  }

  @media (max-height: 700px) {
    max-height: 460px;
    overflow: auto;
  }

  @media (max-height: 675px) and (max-width: 550px) {
    max-height: 500px;
    overflow: auto;
  }

  @media (max-height: 650px) {
    max-height: 480px;
    overflow: auto;
  }

  @media (max-height: 625px) {
    max-height: 440px;
    overflow: auto;
  }

  @media (max-height: 600px) {
    max-height: 440px;
    overflow: auto;
  }

  @media (max-height: 575px) {
    max-height: 420px;
    overflow: auto;
  }

  @media (max-height: 550px) {
    max-height: 370px;
    overflow: auto;
  }

  @media (max-height: 500px) {
    max-height: 340px;
    overflow: auto;
  }

  @media (max-height: 450px) {
    max-height: 280px;
    overflow: auto;
  }

  @media (max-height: 425px) {
    max-height: 301px;
    overflow: auto;
  }

  @media (max-height: 400px) {
    max-height: 220px;
    overflow: auto;
  }

  @media (max-height: 375px) {
    max-height: 249px;
    overflow: auto;
  }

  @media (max-height: 350px) {
    max-height: 180px;
    overflow: auto;
  }
}

.font-16 {
  font-size: $font-medium;
}

.tbody {
  display: block;
  max-height: 200px;
  overflow-y: scroll;

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  // sass-lint:disable no-vendor-prefixes
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    //border-radius: 10px;
    background: $color-grey-light;
  }

  //sass-lint:enable no-vendor-prefixes
}

.thead {
  display: table;
  table-layout: fixed;
  width: calc(100% - 10px);

  tr {
    border-top: 1px solid $color-disabled;
  }
}

.table-bordered {
  border: none;
}

.bottom-time-block {
  height: 70px;
  position: fixed;
  right: 6px;
  bottom: 10px;
  display: flex;
  align-items: end;

  &__time {
    font-size: $font-58;
    line-height: 1;
  }
}

.tooltip-info {
  font-size: $font-medium;
  transform: translate(10%, 35%);
  vertical-align: middle;
}

.uib-modal-center {
  // sass-lint:disable no-important
  text-align: center;
  padding: 0 !important;
  font-family: $family-helvetica;

  // sass-lint:enable no-important
  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}

.colorpicker-position-top {
  margin: -20px 0 0 10px;
}

.toast-top-right {
  // sass-lint:disable no-important
  top: 85px !important;
  // sass-lint:enable no-important
}

.toast-top-left {
  // sass-lint:disable no-important
  top: 85px !important;
  // sass-lint:enable no-important
}

.toast-container {
  position: fixed;
}

.tag-height {
  height: 60px;
  overflow-y: auto;
}

.pointers-none {
  pointer-events: none;
}

.overflow-auto-tabs {
  overflow-y: auto;
  overflow-x: hidden;
}

.thick-scrollbar {
  // sass-lint:disable  no-vendor-prefixes
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-sky-blue;
  }

  //sass-lint:enable no-vendor-prefixes
}

.overflow-tab {
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.tab-pane-m-p {
  // sass-lint:disable no-important
  margin-top: 0 !important;
  padding-top: 0 !important;
  // sass-lint:enable no-important
}

.inner-tab > .active > a {
  font-weight: bold;
  // sass-lint:disable no-important
  background-color: $color-white !important;
  // sass-lint:enable no-important
}

.bottom-grey-border {
  border-bottom: 1px solid $color-grey-90;
}

.border-top-0 {
  // sass-lint:disable no-important
  border-top: none;
  // sass-lint:enable no-important
}

.border-top-0-important {
  // sass-lint:disable no-important
  border-top: none !important;
  // sass-lint:enable no-important
}

.fix-footer {
  @include mobile-all() {
    .btn-lg {
      font-size: $font-10;
    }
  }

  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  background: $color-wild-sand;
  width: 100%;
  padding-bottom: 9px;
  z-index: 10;

  &--right {
    @media only screen and (max-width: 991px) {
      float: none;
    }

    float: right;
    padding: 0;
  }
}

.training-mode {
  @include mobile-all-large() {
    margin: 5px 0 0 -20px;
  }

  padding: 0 7px;
  margin: 0;
}

.selected-table {
  // sass-lint:disable no-important
  background-color: $color-lightsalmon !important;
  // sass-lint:enable no-important
}

.disabled-tab {
  // sass-lint:disable no-important
  cursor: not-allowed;
  color: $color-grey-light;
  pointer-events: none;
  // sass-lint:enable no-important
}

.has-error {
  border-color: $color-red-extreme !important;
}

.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning {
  border-color: $color-warning;
}

.tag-input {
  // sass-lint:disable no-important
  border: 1px solid $color-grey-80 !important;
  box-shadow: none !important;
  // sass-lint:enable no-important
}

.fieldset {
  display: block;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0.35em;
  padding-bottom: 0.35em;
  padding-left: 0.35em;
  padding-right: 0.35em;
  border: 1px solid $color-grey-light;
}

.custom-fieldset {
  padding-left: 1em;
}

.reset-style {
  all: unset;
}

.panel-overflow {
  overflow-y: auto;
  overflow-x: hidden;

  // sass-lint:disable no-vendor-prefixes
  &::-webkit-scrollbar {
    width: 3px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-sky-blue;
  }

  //sass-lint:enable no-vendor-prefixes
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-dollar {
  position: relative;

  &.left > input {
    padding-left: 25px;
  }

  &::before {
    position: absolute;
    top: 8px;
    padding-left: 10px;
    content: "$";
  }
}

.dropdown-multiselect > button {
  // sass-lint:disable no-important
  color: $color-davs-gray !important;
  // sass-lint:enable no-important
}

.numpad {
  &__input {
    @include desktop-large() {
      min-height: 96px;
    }

    min-height: 85px;
  }

  &__btn {
    @include mobile-all-range() {
      min-height: 49px;
      height: 49px;
    }

    @include tablet() {
      min-height: 56px;
      height: 56px;
    }

    @include desktop() {
      min-height: 76px;
    }

    @include desktop-large() {
      min-height: 107px;
    }

    min-height: 76px;
    height: 76px;
  }
}

.ag-grid-custom-row {
  //height: 30px !important;
  line-height: 18px;
  vertical-align: middle;
  font-size: $font-14;
  padding: 0 3px;
}

//font-awesome glyph customization
.faplus {
  font-size: $font-03em;
  vertical-align: middle;
  color: $color-add;
}

.normal-plus {
  vertical-align: middle;
  color: $color-add;
}

.ag-grid-custom-header {
  font-size: $font-normal;
  color: $color-orange-extreme;
  //height: 30px !important;
  text-align: left;
  padding-left: 2px;

  .ag-header-icon {
    float: right;
    padding-right: 2px;
  }
}

.faprint {
  font-size: $font-03em;
  vertical-align: middle;
  color: $color-print;
}

.fatimes {
  font-size: $font-07em;
}

.faeditlist {
  font-size: $font-02em;
  color: $color-sky-blue;
  //margin-top: 2px;
}

.faedit {
  color: $color-sky-blue;
  margin-top: 2px;
  font-size: $font-0-9em;
}

.faedittoggleinvoicearea {
  color: $color-white;
}

.fatrash,
.facopy,
.fatest {
  font-size: $font-17em;
  color: $color-red-trash;
}

.fatrashregularorderinvoice {
  color: $color-red-invoice-trash;
  font-size: $font-0-8-5em;
}

.fatrashregularorderinvoicedisable {
  color: $color-grey;
  font-size: $font-0-8-5em;
}

.fadashboardmanagement {
  font-size: $font-14;
}

.fabars {
  font-size: $font-04em;
}

.faclock {
  font-size: $font-14;
}

.facheckcircle {
  font-size: $font-02em;
  color: $color-green;
}

.facheckcirclemaketable {
  font-size: $font-15;
}

.faimage {
  color: $color-image;
}

.fa-image {
  font-size: $font-17;
}

.fabug {
  font-size: $font-2em;
}

.fasearch {
  margin-top: 2px;
  font-size: $font-02em;
  color: $color-grey;
}

.print-ordermanagement {
  font-size: $font-0-9em;
  color: $color-black;
}

.falightbulbpickscreen {
  color: $color-black;
  vertical-align: middle;
}

.ag-cell-edit-input {
  font-size: $font-normal;
}

.fasquare {
  font-size: $font-01em;
  color: $color-sky-blue;
}

.fasquare-o {
  font-size: $font-01em;
  color: $color-grey;
}

.max-height-51 {
  max-height: 51vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-x-visible {
  .ui-table-wrapper,
  .ui-table-scrollable-wrapper {
    // sass-lint:disable no-important
    overflow-x: visible !important;
    // sass-lint:enable no-important
  }
}

.min-height {
  min-height: 51vh;
  max-height: 51vh;
  overflow-y: auto;
  // sass-lint:disable no-vendor-prefixes
  // &::-webkit-scrollbar {
  //     width: 3px;
  // }

  // &::-webkit-scrollbar-track {
  //     border-radius: 5px;
  // }

  // &::-webkit-scrollbar-thumb {
  //     border-radius: 10px;
  //     background: $color-sky-blue;
  // }
  //sass-lint:enable no-vendor-prefixes
}

.visibility-hidden {
  visibility: hidden;
}

.account-selection-visibility {
  visibility: hidden;
}

.border-right-theme-primary {
  border-right: 1px solid $theme-color-primary;
}

.border-left-theme-primary {
  border-left: 1px solid $theme-color-primary;
}

.border-header-top {
  border-top: 8px solid $theme-color-separator;
}

.border-primary {
  border: 1px solid $theme-color-primary;
}

.tab-top-border {
  // sass-lint:disable no-important
  border-top: none !important;
  // sass-lint:enable no-important
}

.validate {
  // sass-lint:disable no-important
  border: 1px solid $color-red-extreme !important;
  border-radius: 4px;
  color: $color-red-extreme !important;
  // sass-lint:enable no-important
}

.ng-select {
  &.select-disabled,
  &.ng-select-disabled {
    @extend .disabled;
    pointer-events: inherit;

    .ng-select-container {
      @extend .disabled;
      color: $color-black;
      pointer-events: inherit;
      // sass-lint:disable no-important
      cursor: not-allowed !important;
      background-color: $color-grey-alto !important;
      // sass-lint:enable no-important

      .ng-value-container .ng-input > input {
        cursor: not-allowed;
      }
    }

    .ng-arrow-wrapper {
      cursor: not-allowed !important;
    }
  }
}

.pre-wrap {
  white-space: pre-wrap;
  word-break: keep-all;
}

.ui-datepicker {
  &.ui-widget {
    display: block !important;
    min-width: 265px !important;
    z-index: 9999 !important;
  }

  &.ui-datepicker-timeonly {
    min-width: 215px !important;
  }
}

.ui-tooltip {
  width: fit-content;
  color: white !important;
  background-color: #666666;
  opacity: 1;
  z-index: 10;

  .ui-tooltip-text {
    // sass-lint:disable no-important
    max-width: 200px !important;
    padding: 3px 8px !important;
    color: $color-white !important;
    text-align: center !important;
    background-color: $color-black !important;
    border-radius: 4px !important;
    // sass-lint:enable no-important
  }

  .ui-tooltip-arrow {
    // sass-lint:disable no-important
    border-top-color: $color-black !important;
    // sass-lint:enable no-important
  }
}

.display-contents {
  display: contents;
}

.display-flex {
  display: flex;
}

.display-flex-row {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.flex-flow-column {
  flex-flow: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.display-table {
  display: table;
}

.ui-button-text {
  // sass-lint:disable no-important
  padding: 0.51em !important;
  // sass-lint:enable no-important
}

.modal-zindex {
  z-index: 1050;
}

.no-right-border {
  // sass-lint:disable no-important
  border-right: none !important;
  // sass-lint:enable no-important
}

.no-left-border {
  // sass-lint:disable no-important
  border-left: none !important;
  // sass-lint:enable no-important
}

.header-lightgrey {
  color: $color-grey-light-1;
}

.font-17em {
  font-size: $font-17em;
}

.float-right {
  float: right;
}

.full-screen {
  object-fit: unset;
  width: 100%;
  height: 100%;
  background-position: center;
}

.media-rotator {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: auto;
  width: auto;
  overflow: hidden;
}

.notification-popup {
  width: 410px;
  height: 72px;
  position: fixed;
  right: 0;
  top: 80px;
  padding-top: 10px;
  z-index: 5000;

  &__message {
    width: 400px;
    top: 10px;

    border: none;

    &:hover {
      box-shadow: 0 0 24px $color-grey-light;
    }

    &__text {
      float: left;
      width: 100%;
      height: calc(100% - 20px);
      padding: 10px;
      padding-bottom: 0;
      border: none;
      display: block;
      background: $color-white;
      box-shadow: 0 0 14px $color-grey-light;
      border-radius: 10px;

      h3 {
        font-weight: bold;
        font-style: italic;
      }
    }
  }
}

.row-grouping-reorder {
  td {
    // sass-lint:disable no-important
    border-top: none !important;
    // sass-lint:enable no-important
  }
}

.display-none {
  display: none;
}

.display-none-imp {
  // sass-lint:disable no-important
  display: none !important;
  // sass-lint:enable no-important
}

.online-ordering-setting {
  .setting-title {
    font-size: $font-title;
    font-weight: bold;
  }

  .description {
    font-style: italic;
  }
}

.modal {
  &.fade {
    opacity: 1;

    .modal-dialog {
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }

  &.in {
    .modal-dialog {
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
}

.word-break-all {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.reach-textbox {
  ul > li {
    margin-left: 15px;
    list-style-type: disc;
  }

  ol > li {
    margin-left: 7px;
    list-style-type: decimal;
  }
}

.no-cursor-important {
  // sass-lint:disable no-important
  cursor: not-allowed !important;
  // sass-lint:enable no-important
}

.font-nobold {
  font-weight: normal;
}

.table-header {
  .account-attributes {
    // sass-lint:disable no-important
    padding: 0 !important;
    // sass-lint:enable no-important
  }
}

.volume-circle {
  padding-right: 5px;
  padding-left: 4px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 5px;
  border-radius: 49%;
  width: 30px;
}

.badge {
  --bs-border-radius: 10px;
  background-color: #777;
}

.badge-sm {
  padding-right: 3px;
  padding-left: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-radius: 49%;
  width: 24px;
}

.pointer-events-all {
  pointer-events: all;
}

.order-summary {
  // @media (max-width: 1240px) {
  //     height: 529px;
  // }

  @media (max-width: 992px) {
    margin-top: 10px;
  }

  @include mobile-all-large() {
    border-bottom: none;
    height: 300px;
  }

  background: $color-white;
  border: 1px solid $color-grey-light;
  padding-top: 10px;
  border-bottom: 1px solid $color-grey-light;
  padding-bottom: 0;
  float: none;
  display: inline-block;
  height: 524px;
  z-index: 11;

  &__title-cap {
    margin-left: 5px;
    position: relative;
    top: -7px;
  }

  &__title {
    color: $color-grey-extreme;
    font-size: $font-xx-large;
  }

  &__items {
    // @include desktop-large() {
    //     height: 565px;
    // }

    // @include mobile-portrait-320-700() {
    //     height: 360px;
    //     position: relative;
    // }

    // @media only screen and (min-device-width: 320px) and (max-device-width: 766px) and (orientation: landscape) {
    //     height: 100px;
    // }

    // height: 300px;
    padding-right: 10px;
    overflow-y: auto;
    position: relative;
  }

  &__totals {
    @media (max-width: 980px) {
      width: 100%;
    }

    float: right;
    width: 62%;
    //padding-top: 20px;
    // padding-right: 20px;

    &--total {
      @include mobile-all-large() {
        width: 60px;
      }

      width: 100px;
      text-align: right;
      float: right;
    }

    &--grant-total {
      font-size: $font-17;
      color: $theme-color-separator;
      float: right;
    }
  }
}

.ui-table {
  .ui-state-highlight {
    tr,
    td {
      // sass-lint:disable no-important
      background-color: $theme-color-primary !important;
      color: $theme-font-color-primary !important;
      // sass-lint:enable no-important
    }
  }
}

.shiftduties-alert {
  background-color: $color-red;
}

.uib-modal-zindex {
  // sass-lint:disable no-important
  z-index: 1099 !important;
  // sass-lint:enable no-important
}

.terminal-report-navbar {
  position: absolute;
  background: $theme-color-primary;
  color: $theme-font-color-primary;
  text-align: center;
  right: 15px;
  z-index: 10000;
  width: 200px;
  cursor: pointer;
}

.modal-backdrop {
  opacity: 0.5 !important;
}

.align-left {
  // sass-lint:disable no-important
  text-align: left !important;
  // sass-lint:enable no-important
}

.text-align-right {
  text-align: right;
}

.text-align-end {
  text-align: end;
}

.input-color {
  width: 15px;
  position: absolute;
  right: 15px;
  height: 34px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #b3b2ae;
  border-left: 0;
}

.input-color:has(+ .form-control:focus) {
  border-color: #66afe9;
}

.input-color:has(+ ng-select.ng-select-focused:not(.ng-select-opened)) {
  border-color: #007eff;
}

.input-color-close {
  position: absolute;
  right: 50px;

  &::before {
    position: absolute;
    top: 7px;
    content: "X";
    cursor: pointer;
  }
}

.disable-volume-slider {
  .slider-handle {
    // sass-lint:disable no-important
    background-color: $color-grey !important;
    background-image: unset !important;
    cursor: none;
    // sass-lint:enable no-important
  }
}

.width-95 {
  width: 95%;
}

.div-center {
  margin-left: auto;
  margin-right: auto;
  // width: 40%;
}

.popover {
  --bs-popover-font-size: $font-normal;
  --bs-font-sans-san-serif: $family-open-sans;
  --bs-popover-body-padding-y: 0.6rem;
}

.cdk-drag-preview {
  // sass-lint:disable no-important
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px $color-grey,
    0 8px 10px 1px $color-grey,
    0 3px 14px 2px $color-grey;
  z-index: 9999 !important;
  // sass-lint:enable no-important
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging {
  .example-box {
    &:not(.cdk-drag-placeholder) {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

.action-button-container {
  text-align: right;
  padding-right: 0;
  padding-left: 0;
  border-top: solid 1px $color-grey-light;
}

.position-absolute {
  position: absolute;
}

.justify-content-end-flex {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.print-ingredients {
  position: absolute;
  z-index: 1;
  margin-top: 9px;
}

.lg-checkbox {
  //sass-lint:disable no-important
  height: 14px !important;
  margin-bottom: 3px !important;
  width: 14px !important;
  //sass-lint:enable no-important
}

.refunded-qty {
  background-color: $color-grey-90;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
}

.tab-content > .tab-pane {
  //sass-lint:disable no-important
  width: 100% !important;
  //sass-lint:enable no-important
}

.half-box-div {
  float: right;
  width: 100%;
  background-color: $color-white;
  border-radius: 15px;
  padding: 10px;
  margin-top: 10px;
}

.full-box-div {
  width: 100%;
  background-color: $color-white;
  padding-top: 10px;
  padding-left: 10px;
  border-radius: 15px;
  margin-top: 10px;
  padding-right: 10px;
}

.p-datepicker {
  //sass-lint:disable no-important
  z-index: 2000 !important;

  &.p-datepicker-timeonly {
    z-index: unset !important;
  }

  //sass-lint:enable no-important
}

.p-dropdown-panel,
.p-overlay {
  &.p-component {
    //sass-lint:disable no-important
    z-index: 2000 !important;
    //sass-lint:enable no-important
  }
}

label {
  p-checkbox {
    padding-right: 2px;
  }
}

.toast-container .ngx-toastr {
  --bs-toast-spacing: 5px;
  .toast-message {
    padding-top: 3px;
  }

  .toast-close-button {
    top: 0px;
  }
}

.toast-success {
  background-size: 18px !important;
}

.order-status-heart-rate {
  font-size: $font-7;
  color: $color-white;
  padding-bottom: 29px;
}

.rocket {
  padding-top: 5%;
}

.ng-dropdown-panel {
  z-index: 9999 !important;
}

.button-div {
  height: 25px;
  border-radius: 3px;
  padding: 3px;
}

th.cell-padding {
  padding: 8px 5px !important;
}

td.cell-padding {
  padding: 5px !important;

  &.flex div {
    display: flex;
  }
}

.fa-stack {
  width: 2em;
}

.flex-grow-1 {
  flex-grow: 1;
  text-align: center;
}

.text-align-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.flex-grow {
  flex-grow: 1;
}

.flex-direction-column {
  flex-direction: column;
}

.overflow-break-word {
  overflow-wrap: break-word;
}

.overflow-break-all {
  overflow-wrap: anywhere;
}

.font-weight-600 {
  font-weight: 600;
}

.cron-editor-container {
  .seconds {
    margin-left: 5px;
  }

  .minutes {
    margin-right: 5px;
  }
}

.interactive-menu-item-highlight {
  background-color: var(--menu-item-highlight-color, $color-green-light) !important;
}

.interactive-menu-item-flash {
  animation: product-blinker 1s ease-in-out infinite;
}

@keyframes product-blinker {
  50% {
    background-color: var(--menu-item-highlight-color, $color-green-light);
  }
}

.interactive-menu-item-callout {
  opacity: 0.1;
}

.interactive-group-widget-flash {
  animation: widget-blinker 1s ease-in-out infinite;
}

@keyframes widget-blinker {
  50% {
    opacity: 0;
  }
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-09 {
  opacity: 0.9;
}

.btn-orange {
  background-color: $theme-color-separator;
  border-color: $theme-color-separator;
  color: $theme-font-color-separator;

  --bs-btn-active-border-color: var(--branding-header-separator-color, "#ff790c");
  --bs-btn-active-bg: var(--branding-header-separator-color, "#ff790c");
  --bs-btn-active-color: var(--branding-header-separator-font-color, "#ffffff");

  &:hover,
  &:focus {
    background-color: $theme-color-separator;
    color: $theme-font-color-separator;
  }
}

.theme-separator-border {
  border: 1px solid $theme-color-separator;
}

.menu-explorer-primary-button {
  background: #4472c4;
  border-radius: 10px;
  color: white;
  font-weight: normal;
  cursor: pointer;
  font-size: larger;

  &:focus,
  &:hover {
    color: white;
  }
}

.border-radius {
  border-radius: 10px;
}

.font-size-larger {
  font-size: larger;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $color-grey-light;
  }
}

.text-shadow-none {
  text-shadow: none !important;
}

.list-style-type-disc {
  list-style-type: disc;
}

.setting-title {
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 0 !important;
}

.table-border-none.table > tbody > tr {
  > td,
  > th {
    border: none;
  }
}

.nav-tabs.pos-nav-tabs {
  > li.active {
    border-bottom: 1px solid $theme-color-separator !important;
    color: #555;

    a {
      color: #555;
    }
  }

  > li {
    margin-bottom: -1px;

    > a {
      background-color: transparent;
      border: 1px solid transparent;
      color: $color-blue;
      cursor: pointer;
      display: block;
      padding: 10px 15px;
    }

    > a:hover {
      color: #23527c;
      border: 1px solid transparent;
    }
  }
}

.shadow {
  box-shadow: 0 2px 6px #d3d3d3;
}

.stripe-even {
  background-image: radial-gradient(#0000ff2e 3px, transparent 0);
  background-size: 20px 20px;
}

.stripe-odd {
  background-image: radial-gradient(#ff00002e 3px, transparent 0);
  background-size: 20px 20px;
}

.p-checkbox-label {
  margin-bottom: 0px;
}

p-tristatecheckbox {
  display: inline-flex;
  vertical-align: bottom;
  align-items: center;
}

.ng-select .ng-select-container {
  min-height: 34px;
}

.ng-select.ng-select-single .ng-select-container {
  height: 34px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 7px;
}

.display-initial {
  display: initial;
}

input[type="number"] {
  text-align: right;
}

.section-border {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgb(211, 211, 211, 0.5);
  margin-top: 10px;
}

.price-input {
  width: 120px !important;
}

.btn-save {
  margin-right: 5px;
  font-weight: normal;
  color: white;
}

.btn-cancel {
  margin-right: 20px;
  font-weight: normal;
}

.btn-cancel-modal {
  margin-right: 10px;
  font-weight: normal;
}

.page-footer {
  padding-top: 10px;
  margin-top: 15px;
}

.popup-footer {
  padding-top: 10px;
  margin-top: 20px;
}

.p-datepicker-trigger {
  padding: 0.4rem 0 !important;
}

.remove-icon-badge {
  display: inline-block;
  padding: 1px 3px;
  font-size: 10px;
  background-color: #777;
  border-radius: 10px;
  line-height: 1;
  color: #fff;
  vertical-align: middle;
}

.custom-tooltip {
  font-size: 12px;
  padding: 3px 8px !important;
  text-align: center;
  color: $color-white;
  border-radius: 3px;
}

.content-scroll {
  max-height: calc(100vh - 251px);
  overflow: auto;
}

.gridster-item-resizable-handler.handle-n,
.handle-s {
  height: 2px !important;
}

.gridster-item-resizable-handler.handle-e,
.handle-w {
  width: 2px !important;
}

.gridster-item-resizable-handler.handle-ne,
.handle-se,
.handle-nw,
.handle-sw {
  height: 2px !important;
  width: 2px !important;
  border-width: 0 0 2px 2px !important;
}

field-info > span {
  position: relative !important;
}

.tags-theme .ng-select-container {
  .ng-arrow-wrapper {
    display: none;
  }
}

.btn-default.btn-radio {
  text-shadow: none;
}

.btn-radio {
  font-weight: normal;
}

.p-datepicker table td {
  padding: 0.5rem !important;
}

.p-datepicker .p-datepicker-header .p-datepicker-next {
  height: 2rem;
  border: 0 none;
}

.p-calendar .p-inputtext {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-calendar .p-button {
  color: $color-white;
  border-radius: 0 3px 3px 0;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #f44336;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  color: #495057;
}

.input-group-addon {
  padding: 9px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-group > .form-control {
  flex: inherit;
}

.p-datepicker .p-datepicker-header {
  .p-datepicker-next,
  .p-datepicker-prev {
    height: 2rem;
    border: 0 none;
  }
}

.p-datepicker .p-timepicker button {
  height: 2rem;
}

.ng-select .ng-clear-wrapper .ng-clear {
  line-height: unset !important;
}

.thumbnail {
  border: 1px solid #ddd;
}

.pos-modal-body {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  padding-bottom: 3px;
}

.pos-page-body {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  padding-bottom: 3px;
}

.pos-tab-body {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  padding-bottom: 3px;
}

.pos-title-content {
  padding: 0 0.5rem;
}

.pos-sub-content {
  padding: 0 0 0 1.8rem;
}

.dropdown-item:active {
  background-color: unset;
  color: unset;
}

.vertical-align-center {
  vertical-align: middle;
}

.cursor-no-drop {
  cursor: no-drop;
}

.list-group-item {
  border-radius: 5px !important;
}

.drag-item {
  background-color: #f5f5f5;
  border-radius: 5px;
  height: auto;
  align-items: center;
}

.inline-form-operation-button {
  padding-top: 35px;

  @include mobile-all() {
    padding-top: 10px;
    margin-left: 5px;
  }
}

.dropdown-toggle::after {
  vertical-align: middle;
}

.vertical-text-bottom {
  vertical-align: text-bottom;
}

.kiosk-bg-light-grey {
  background-color: rgb(243, 243, 243, var(--bg-opacity, 1));
}

.kiosk-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.shake-animation {
  animation: shake 1s ease-in-out infinite;
}

@keyframes shake {
  0% {
    transform: rotate(-15deg);
  }
  4% {
    transform: rotate(15deg);
  }
  16% {
    transform: rotate(-22deg);
  }
  20% {
    transform: rotate(22deg);
  }
  32% {
    transform: rotate(-12deg);
  }
  36% {
    transform: rotate(12deg);
  }
  40%,
  100% {
    transform: rotate(0deg);
  }
}
