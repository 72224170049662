﻿@import "../vars/colors";

//background color
.bg-white {
    background-color: $color-white;
}

.bg-antiquewhite {
    background-color: $color-antiquewhite;
}

.bg-green {
    // sass-lint:disable no-important
    background-color: $color-green !important;
    // sass-lint:enable no-important
}

.bg-green-light-x {
    background-color: $color-green-light-x;
}

.bg-monte-carlo {
    background-color: $color-monte-carlo;
}

.bg-green-light {
    background-color: $color-green-light;
}

.bg-grey-90 {
    background-color: $color-grey-90;
}

.bg-light-green {
    // sass-lint:disable no-important
    background-color: $color-light-green !important;
    // sass-lint:enable no-important
}

.bg-light-sea-green {
    background-color: $color-light-sea-green;
}

.bg-bright-green {
    background-color: $color-bright-green;
}

.bg-orange {
    background-color: $theme-color-separator;
}

.bg-dark-orange {
    background-color: $color-orange-dark;
}


.bg-black {
    background-color: $color-black;
}

.bg-extreme-orange {
    // sass-lint:disable no-important
    background-color: $color-orange-extreme !important;
    // sass-lint:enable no-important
}

.bg-grey {
    background-color: $color-grey;
}

.bg-light-grey {
    background-color: $color-grey-light;
}

.bg-light-grey-0 {
    background-color: $color-grey-light-0;
}

.bg-color-grey-90 {
    background-color: $color-grey-90;
}

.bg-grey-alto {
    background-color: $color-grey-alto;
}

.bg-yellow {
    background-color: $color-yellow;
}

.bg-red {
    background-color: $color-red;
}

.bg-olivine {
    background-color: $color-olivine;
}

.bg-geyser {
    background-color: $color-geyser;
}

.bg-alabaster {
    // sass-lint:disable no-important
    background-color: $color-alabaster !important;
    // sass-lint:enable no-important
}

.bg-medium-violet {
    background-color: $color-medium-violet;
}

.bg-deep-sky-blue {
    background-color: $color-deepskyblue;
}

.bg-lightblue {
    background-color: $color-lightblue;
}

.bg-gold {
    background-color: $color-gold;
}

.bg-lightsalmon {
    background-color: $color-lightsalmon;
}

.bg-electric-violet {
    background-color: $color-electric-violet;
}

.bg-portage {
    background-color: $color-portage;
}

.bg-sky-blue-dark {
    background-color: $color-sky-blue-dark;
}

.bg-light-red {
    background-color: $color-lightred; 
}

.bg-light-red-important {
    // sass-lint:disable no-important
    background-color: $color-lightred !important;
    // sass-lint:enable no-important
}

.bg-light-orange {
    background-color: $color-lightorange;
}

.bg-light-orange-important {
    // sass-lint:disable no-important
    background-color: $color-lightorange !important;
    // sass-lint:enable no-important
}

.bg-orange-light_1-important {
    // sass-lint:disable no-important
    background-color: $color-orange-light_1 !important;
    // sass-lint:enable no-important
}

.bg-light-blue {
    background-color: $color-lightblue; // !important;
}

.bg-light-blue-important {
    // sass-lint:disable no-important
    background-color: $color-lightblue !important;
    // sass-lint:enable no-important
}

.bg-success {
    background-color: $color-success;
}

//color
.grey {
    color: $color-grey;
}

.light-grey {
    color: $color-grey-light;
}

.white-grey {
    color: #9f9e9e;
}

.grey-dark {
    color: $color-grey-dark;
}

.green {
    color: $color-green;
}

.monte-carlo {
    color: $color-monte-carlo;
}

.theme-primary {
    color: $theme-color-primary;
}

.light-green {
    color: $color-green-light;
}

.blue {
    color: $color-blue;
}
.deep-sky-blue {
    color: $color-deepskyblue;
}
.sky-blue {
    color: $color-sky-blue;
}

.blueviolet {
    color: $color-blueviolet;
}

.portage {
    color: $color-portage;
}

.black {
    // sass-lint:disable no-important
    color: $color-black !important;
    // sass-lint:enable no-important
}

.p-pagination-light-blue{
    color: #E3F2F9;
}

.lightsalmon {
    color: $color-lightsalmon;
}

.lightgray {
    color: $color-lightgray
}

.dark-purple {
    color: #a320bd;
}

.white {
    // sass-lint:disable no-important
    color: $color-white !important;
    // sass-lint:enable no-important
}

.red {
    color: $color-red;
}

.orange {
    color: $color-orange;
}

.warning-light-orange{
    color: $color-warning-light-orange
}

.bg-warning-light-orange{
    background-color: $color-warning-light-orange
}

.extreme-orange {
    color: $color-orange-extreme;
}

.yellow {
    color: $color-yellow;
}

.dark-violet {
    color: $color-dark-violet;
}

.medium-violet {
    color: $color-medium-violet;
}

.gold {
    color: $color-gold;
}

.green-light {
    color: $color-green-light;
}

.mine-shaft {
    // sass-lint:disable no-important
    color: $color-mine-shaft !important;
    // sass-lint:enable no-important    
}

.bg-theme-primary {
    // sass-lint:disable no-important
    background-color: $theme-color-primary !important;
    color: $theme-font-color-primary !important;
    // sass-lint:enable no-important

    &:hover {
        // sass-lint:disable no-important
        color: $theme-font-color-primary !important;
        // sass-lint:enable no-important
    }
}

.bg-theme-separator {
    // sass-lint:disable no-important
    background-color: $theme-color-separator !important;
    color: $theme-font-color-separator !important;
    // sass-lint:enable no-important

    &:hover {
        // sass-lint:disable no-important
        color: $theme-font-color-separator !important;
        // sass-lint:enable no-important
    }
}


.theme-separator {
    color: $theme-color-separator;
}

.color-grey-dark-6{
    color: $color-grey-dark-6;
}

.color-grey-9{
    color: $color-grey-9;
}

.theme-stroke {
    stroke: $theme-color-separator;
    stroke-width: 10px;
}

.red-extreme {
    // sass-lint:disable no-important
    color: $color-red-extreme !important;
    // sass-lint:enable no-important
}

.dark-orange {
    color: $color-orange-dark;
}

.green-background {
    background-color: #d0f9d0 !important;
}

.green-light {
    color: $color-green-light;
}

.add-color {
    color: $color-add !important;
};

.bg-add {
    background-color: $color-add !important;
}

.bg-print {
    background-color: $color-print !important;
}

.icon-bg-default {
    color: $color-sky-blue;
}

.bg-edit {
    background-color: $color-sky-blue;
    &:hover, &:active, &:focus-visible {
        --bs-btn-active-bg: #54a4de;
        --bs-btn-active-border-color: #54a4de;
        background-color: $color-sky-blue;
    }
}

.delete-color {
    color: $color-red-trash;
}

.dashboard-color {
    color: orangered;
}

.notification-color{
    color:#d55b18;
}

.burger-color {
    color: green;
}

.ban-color {
    color: #ea5831;
}

.check-circle-color {
    color: green;
}

.orange-red {
    color: orangered;
}

.warning-color {
    color: $color-warning
}

.transparent-color {
    color: transparent;
}

.light-orange-yellow{
    color: #ff9b49;
}

.bg-varden {
    background-color: $color-varden !important;
}

.order-kiosk-color {
    color: #ff6fb1;
}

.wait-list-terminal-color {
    color: brown;
}

.loyalty-form-submited-circle-color{
    color: #015b01;
}
