﻿@import "../../vars/colors";
@import "../../vars/fonts";
@import "../../shared/mixins";

.main-header {
    position: fixed;
    float: left;
    width: 100%;
    z-index: 100;

    &__block {
        @include mobi-tab() {
            position: inherit;
        }

        @include mobile-x-small() {
            float: left;
            position: relative;
        }

        background: $theme-color-primary;
        color: $theme-font-color-primary;
        border-top: 8px solid $theme-color-separator;
        z-index: 15;
        padding: 0;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;


        &__logo {
            float: left;
            display: flex;
            align-content: center;
            flex-wrap: wrap;
            padding-top: 10px;
            padding-bottom: 10px;

            @include mobile-tablet-768-1125 {
                width: 100%;
                justify-content: center;
            }

            @include mobile-all-large {
                width: 100%;
                justify-content: center;
            }

            &__link {
                @include mobile-all-large() {
                    float: left;
                    width: 100%;
                    text-align: center;

                    .img-responsive {
                        display: inline;
                    }
                }

                @include mobile-tablet-768-1125() {
                    float: left;
                    width: 30%;
                    text-align: center;
                    z-index: 2;
                    .img-responsive {
                        display: inline;
                    }
                }
            }
        }

        &__main-menu {
            z-index: 1000;
            height: 100%;
            @media (width: 768px) {
                width: 78% !important;
            }

            @include mobile-upto-640() {
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }

            @include mobile-x-small() {
                bottom: auto;
                float: left;
                left: 0;
                position: absolute;
                top: -31px;
                width: 100%;
            }

            @include mobile-641-768() {
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }

            @include mobile-all-range() {
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }

            @include mobile-tablet-768-1125 {
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                
                .navbar-default {
                    box-shadow: none;
                }
            }

            &__nav {
                height: 100%;
                width: 100%;
                @include mobile-all-large() {
                    float: none;
                    margin: auto;
                }

                @include mobile-small() {
                    float: none;
                    width: 300px;
                    margin: auto;
                    margin-top: 30px;
                }

                @include mobile-all-range() {
                    float: none;
                    margin: 0;
                    width: 100%;

                }

                @include mobile-tablet-768-1125 {
                    float: none;
                    margin: 0;
                    width: 100%;
                    justify-content: start;

                    .navbar-toggle {
                        display: block;
                    }

                    .navbar-toggler {
                        display: block;
                    }

                    .navbar-collapse {
                        &.collapse {
                            display: none !important;
                            position: absolute;
                            &.show {
                                display: block !important;
                                position: absolute;
                                top: 100%;
                            }
                        }
                    }
                }

                .navbar-header {
                    @include mobile-tablet-768-1125() {
                        float: unset;
                    }


                }

                float: right;

                &__block {
                    background: $theme-color-primary;
                    color: $theme-font-color-primary;
                    justify-content: end;
                    position: unset;
                    @media (max-width: 1125px) {
                        top: 100%;
                        width: 100%;
                        position: absolute;
                        justify-content: flex-start;
                    }
                    &__active-order {
                        color: $theme-color-separator;
                        font-weight: bold;
                        background: $color-white;
                        padding: 3px;
                        float: right;
                        margin-left: 5px;
                        line-height: 6px;
                        margin-top: 4px;
                        font-size: $font-10;
                    }

                    &__sub-menu {
                        @include mobile-all-large() {
                            max-height: 180px;
                            width: 100%;
                            position: initial;
                            border-top: 1px solid $color-white;
                        }

                        @media (min-width: 1126px) and (max-width: 1500px) {
                            right: -200px;
                        }

                        float: left;
                        width: 400px;
                        position: absolute;
                        @media(max-width: 1125px){
                            top: 30px;
                        }
                        top: 70px;
                        background: $theme-color-primary;
                        color: $theme-font-color-primary;
                        z-index: 9999;
                        border-top: 1px solid $color-sinbad;
                        max-height: 300px;
                        overflow: auto;

                        &:hover {
                            display: block;
                        }

                        li {
                            width: 100%;
                            position: relative;

                            a {
                                width: 100%;
                                border-bottom: 1px solid $color-sinbad;
                                padding: 10px;
                                color: $color-white;
                                float: left;
                            }
                        }
                    }

                    

                    .sub-navs {
                        // ,&--active-order {
                        &--mapped-terminal {
                            &:hover {
                                ul {
                                    display: block;
                                }
                            }
                        }
                    }


                    .navbar-nav {
                        @include mobile-all-large() {
                            float: none;
                        }

                        float: left;

                        > li {
                            @include mobile-all-large() {
                                width: 100%;
                                float: left;
                            }

                            @include mobile-tablet-768-1125() {
                                width: 100%;
                                float: left;
                            }

                            @include mobile-tablet-768-1125() {
                                margin-left: 3px;

                                &:first-child {
                                    margin-left: 2px;
                                }
                            }

                            @include mobile-small() {
                                margin-left: 3px;

                                &:first-child {
                                    margin-left: 2px;
                                }
                            }

                            list-style: none;
                            float: left;

                            > a {
                                @media (min-width: 1280px ) {
                                    padding: 25px 10px;
                                }

                                @include mobi-tab() {
                                    padding: 25px 7px;
                                }

                                @include mobile-tablet-768-1125() {
                                    padding: 5px 7px;
                                }

                                @include mobile-all-large() {
                                    padding: 5px 7px;
                                }

                                @include mobile-all-range() {
                                    float: none;
                                    margin: 0;
                                    width: 100%;
                                }

                                color: $color-white;
                                padding: 25px 10px;
                                border-left: none;
                                float: left;

                                &.warning {
                                    background-color: $color-gold !important;
                                    cursor: pointer;

                                    &:hover {
                                        color: $color-white !important;
                                    }
                                }

                                .menu-icon {
                                    @include mobile-all-large() {
                                        display: inline;
                                        width: 25px;
                                        float: left;
                                    }

                                    @include mobile-tablet-768-1125() {
                                        display: inline;
                                        width: 25px;
                                        float: left;
                                    }

                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__user-section {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            align-self: center;
            @include mobile-upto-640() {
                position: absolute;
                right: 0;
                width: 35%;
            }

            @include mobile-x-small() {
                position: absolute;
                width: auto;
            }

            @include mobile-641-768() {
                position: absolute;
                right: 0;
                width: 35%;
            }

            @include mobile-all-range() {
                position: absolute;
                right: 0;
                width: 40%;
            }

            @include mobile-tablet-768-1125() {
                position: absolute;
                right: 0;
                width: 35%;
            };


            &__drop-down {
                @include mobile-tablet-768-1125() {
                    position: absolute;
                    right: 15px;
                    text-align: right;
                }
                @include mobile-all-large() {
                    position: absolute;
                    right: 5px;
                    text-align: right;
                }

                @include mobile-641-768() {
                    position: absolute;
                    right: 15px;
                    text-align: left;
                }

                @include mobile-all-range() {
                    position: absolute;
                    right: 15px;
                    text-align: right;
                }

                z-index: 1001;
                color: $color-white;
                cursor: pointer;
                min-width: 50px;

                &__user-name {
                    color: $theme-font-color-primary;
                    @media (max-width: 991px) {
                        font-size: $font-small;
                    }

                    @media (max-width: 320px) {
                        font-size: $font-x-small;
                    }
                }
            }
        }
    }

    
    .tab-content {
        > .tab-pane {
            width: 100%;
            float: left;
            margin-left: -15px;
            padding-top: 0;
        }
    }
}

.header-content {
    @media (min-width: 992px) {
        width: 350px;
        left: -55%;
    }

    @media (max-width: 991px) {
        left: -70%;
    }
}

.float-right {
    @media (min-width: 992px) {
        text-align: right;
    }
}

.login-dropdown-menu {
    @media (max-width: 1388px) {
        left: -50px;
    }

    @media (max-width: 991px) {
        left: -75px;
    }
}

.badge-icon {
    .popover {
        margin-left: 6px;
    }
}

.impersonate-user {
    background-color: $color-red;
    border-radius: 10px;
    padding: 3px 7px 5px 6px;
}
