﻿@import "../shared/mixins";
@import "../vars/fonts";
@import "../vars/colors";

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: normal;
}


h1,
h2,
h3,
h4,
h5,
h6,
form,
p,
ul {
    margin: 0;
    padding: 0;
}

ul,
li {
    list-style-type: none;
}

label {
    font-weight: normal;
    margin-bottom: 5px;
}

.label-section {
    @include mobi-tab() {
        padding-top: 5px;
    }

    @include desktop-large() {
        padding-top: 7px;
    }

    padding-top: 5px;
}

.control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: left;
}

.has-error .checkbox, 
.has-error .checkbox-inline, 
.has-error .control-label, 
.has-error .help-block, 
.has-error .radio, 
.has-error .radio-inline, 
.has-error.checkbox label, 
.has-error.checkbox-inline label, 
.has-error.radio label, 
.has-error.radio-inline label {
    color: #a94442;
}

//Multiselect dropdown
.multiselect-parent {
    width: 100%;

    .dropdown-toggle {
        width: 100%;
        text-align: left;
        background: $color-white;
        font-weight: normal;
        padding-right: 4px;
        color: $color-black;

        span {
            &.caret {
                float: right;
                margin-top: 8px;
            }
        }
    }

    .dropdown-menu-form {
        width: 100%;
        max-height: 200px;

        li {

            a {
                &:hover {
                    background-color: $color-sky-blue-dark;
                    background-image: none;
                }

                .checkbox {
                    margin-top: 0;
                    margin-bottom: 0;

                    .checkbox-input {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

.dropdown-menu > li > a {
    color: $color-black;
}

.chk-section {
    padding-top: 2px;

    &__input {
        // sass-lint:disable no-important
        margin: -1px 7px 0 0 !important;
    }
}

.rounded-input {
    // sass-lint:disable no-important
    border-radius: 4px !important;
}

img {
    border: none;
    vertical-align: middle;
}

.txt-qty {
    width: 78px;
    float: left;
}
