﻿@import "../shared/colors";
@import "../vars/fonts";
@import "../shared/mixins";

.btn {
    --bs-btn-color: black;
    font-weight: 700;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #0d6efd;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #0a58ca;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
    --bs-btn-bg: #e9e9e9;
    --bs-btn-border-color: #cdcfd0;
    --bs-btn-hover-color: black;
    --bs-btn-hover-bg: #e9e9e9;
    --bs-btn-active-color: black;
    --bs-btn-active-bg: #e9e9e9;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: black;
    --bs-btn-disabled-bg: #ebebeb;
    --bs-btn-disabled-border-color: #ebebeb;
}

.btn-color-white {
    --bs-btn-color: #fff;
}

.seat-btn {
    color: $color-white;
    background-color: #337ab7;
    &:hover {
        color: $color-white;
    }
}

.btn-times {
    padding: 4.5px 9px !important;
    @extend .btn-cross;
    
}

.btn-cross {
    background: $theme-color-separator;
    color: $theme-font-color-separator;
    padding: 6px 12px;
    float: left;
    border-radius: 3px;

    &:hover {
        color: $theme-font-color-separator;
    }
}

.btn-sent-to-kitchen {
    @extend .btn-cross;
    background: $color-green;
    border-radius: 3px;
}

.btn-danger {
    color: $color-white;
    font-weight: 500;
}

.btn-gift {
    @include mobile-all-range() {
        height: auto;
        vertical-align: middle;
    }

    @include mobile-portrait-320-700() {
        height: 76px;
        vertical-align: middle;
    }
}

.btn-modal {
    font-size: $font-normal;

    &__img {
        height: auto;
        width: 28px;
    }
}

.btn-cal {
    @media only screen and (min-width: 1224px) {
        padding: 20px 0;
        font-size: $font-legend;
    }

    @include desktop-small-all() {
        padding: 20px 0;
        font-size: $font-medium;
    }

    @include desktop-large() {
        font-size: $font-cal-desktop;
        padding: 35px 0;
    }

    // TODO: remove this commented code
    // @media (max-width: 910px) {
    //     padding: 10px 0;
    //     font-size: $font-14;
    // }

    // @include mobile-all() {
    //     padding: 5px 0;
    // }

    @include mobile-small() {
        padding: 9px 3px;
        font-size: 13px;
    }

    border: 1px solid $color-silver;
    background-color: $color-grey-light;
    cursor: pointer;
    text-align: center;

    &:active {
        background-color: $color-disabled;
    }

    &__img {
        height: 25px;
    }

    &__button-img {
        height: 48px;
    }

    &__gift-button {
        @include mobile-all() {
            height: 69px;
        }

        height: auto;
    }
}

.btn-cal-green {
    background-color: $color-green-light-x;
}

.btn-cal-clear {
    background-color: $color-red-dark;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-keypad {
    @include desktop-large() {
        font-size: $font-cal-desktop;
    }

    @media (max-width: 540px) {
        padding: 18px 0;
    }

    background-color: $color-grey-light;
    border: 1px solid $color-silver;
    cursor: pointer;
    font-size: $font-cal-keypad;
    font-weight: bolder;
    padding: 35px 0;
    text-align: center;

    &:active {
        background-color: $color-disabled;
    }
}

.clock-in-btn-keypad {
    margin: 5px;
    width: 17%;

    @media (max-width: 540px) {
        margin: 3px;
        width: 29%;
    }
}

.btn-blank {
    background-color: $color-white;
    border: none;
    cursor: default;
    padding: 23.5px 0;
    font-size: $font-x-large;

    @media (min-width: 1824px) {
        padding: 34px 0;
    }

    @media (max-width: 910px) {
        padding: 16px 0;
    }

    @media (max-width: 767px) {
        padding: 14.5px 0;
    }
}

.btn-waning {
    @media (max-width: 980px) {
        font-size: $font-12;
    }

    @include mobile-all-large() {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    @include mobile-small() {
        font-size: $font-x-small;
    }

    background: $color-white;
    color: $theme-color-separator;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 2px solid $color-grey-light-0;
}

.btn-disabled {
    cursor: not-allowed;
    background-color: $color-grey-light-0;
    color: $color-grey-light;
}

.btn-circle {
    width: 50px;
    height: 50px;
    padding: 8px 7px;
    font-size: $font-18;
    line-height: 1.33;
    border-radius: 25px;
}

.btn-inverse {
    color: $color-white;

    &:hover {
        color: $color-min-shaft;
    }
}

.btn-group-toggle {
    .btn {
        width: 85px;
    }
}

.btn-order-management {
    @media (max-width: 1240px) {
        width: 77px;
        font-size: $font-small;
    }

    @include mobile-all-large() {
        width: 100%;
    }
    width: 90px;
    height: 71px;
    font-weight: 500;
    margin-right: 6px;
    padding: 0;
}

.btn-terminal-reports {
    @media (max-width: 480px) {
        width: 32.5%;
        margin-bottom: 5px;
    }

    @media (min-width: 480px) {
        width: 24.3%;
        margin-bottom: 5px;
    }

    @media (min-width: 600px) {
        width: 19.4%;
    }
    font-weight: 500;
    padding: 0;
}

.btn-monte-carlo {
    background: $theme-color-primary;
    color: $theme-font-color-primary;
    font-style: normal;
    font-size: $font-normal;
    padding-right: 5px;
    padding-left: 5px;
}

button {
    cursor: pointer;
    border: none;
    overflow: visible;
    line-height: normal;
    height: auto;
}
.fnc-btn-order-management {
    @media (max-width: 980px) {
        font-size: $font-12;
    }

    @include mobile-all-large() {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    @include mobile-small() {
        font-size: $font-x-small;
        padding-bottom: 4px;
    }

    color: $color-black;
    background-color: $color-white;
    border-radius: 0;
    border: 2px solid $color-grey-90;
    text-transform: uppercase;
    width: 147px;
    margin-right: 5px;
    &:hover {
        color: $color-black;
    }
}

.btn-custom {
    @media only screen and (max-width: 1279px) {
        padding: 20px 0;
        font-size: $font-1em;
    }
}

.btn-border {
    border: 1px solid $color-lightgray;
}

.btn-chk-box {
    // sass-lint:disable no-important

    height: 17px !important;
    width: 17px !important;

    //sass-lint:enable no-important
}
