﻿@import "../vars/fonts";


.font-small {
    font-size: $font-small;
}

.font-normal {
    font-size: $font-normal;
}


.font-x-normal {
    font-size: $font-x-normal;
}
.font-menu {
    font-size: $font-menu;
}

.font-medium {
    font-size: $font-medium;
}

.font-0-8-5em {
    font-size: $font-0-8-5em;
}


.font-0-9em {
    font-size: $font-0-9em;
}

.font-1em {
    font-size: $font-1em;
}

.font-1-17em {
    font-size: $font-17em;
}

.font-02 {
    font-size: $font-02em;
}

.font-05 {
    font-size: $font-05em;
}

.font-10 {
    font-size: $font-10;
}

.font-11 {
    font-size: $font-11;
}

.font-12 {
    font-size: $font-12;
}

.font-13 {
    font-size: $font-13;
}

.font-14 {
    font-size: $font-14;
}

.font-15 {
    font-size: $font-15;
}

.font-17 {
    font-size: $font-17;
}

.font-18 {
    font-size: $font-18;
}

.font-18-5 {
    font-size: $font-18-5;
}

.font-19 {
    font-size: $font-19;
}

.font-20 {
    font-size: $font-20;
}

.font-21 {
    font-size: $font-21;
}

.font-22 {
    font-size: $font-22;
}

.font-23 {
    font-size: $font-23;
}

.font-24 {
    font-size: $font-x-large;
}

.font-25 {
    font-size: $font-25;
}

.font-33 {
    font-size: $font-33;
}

.font-40 {
    font-size: $font-40;
}

.font-50 {
    font-size: $font-50;
}

.font-80 {
    font-size: $font-80;
}

.font-3-times {
    font-size: $font-3-times;
}

.font-2-5vh {
    font-size: $font-2-5vh;
}

.font-1-4em {
    font-size: $font-04em;
}

.font-1-7em {
    font-size: $font-07em;
}

.font-2-6em {
    font-size: $font-2-6em;
}

.font-3em {
    font-size: $font-3em;
}

.font-85{
    font-size: 85%;
}
