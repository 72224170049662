﻿@import "../../vars/fonts";
@import "../../shared/colors";

.widget-add-modal {
    max-height: 70vh;
    overflow: auto;
    &__block {
        padding-left: 28px;

        &__widget {
            float: left;
            width: 28%;
            margin-right: 5%;

            &__title {
                font-size: $font-20;
                text-align: center;
            }

            &__icon-block {
                text-align: center;
                color: $color-grey;

                &__icon {
                    font-size: $font-98;
                }
            }
        }
    }
}
