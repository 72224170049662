modal-container {
  // display: table !important;
  height: 100%;
  width: 100%;
  pointer-events: none;

  > .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - 0px);
    pointer-events: none;
    margin: 0 auto;
  }
}

@import "./styles/shared/colors";
@import "./app/shared/table/prime-ng-control.scss";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import 'simple-keyboard/build/css/index.css';


$enable-important-utilities: false; // this prevents bootstrap from applying !important in utility classes
$modal-lg: 900px; //overrides the default width 800px
@import "bootstrap/scss/bootstrap.scss";

$custom-primary: $theme-color-primary;
$custom-primary-font: $theme-font-color-primary;
$custom-primary-dark: darken($color-monte-carlo, 10%);

// apply theme to the container
.custom-theme .ng-select-container {
  .ng-value-container .ng-placeholder {
    padding-top: 2px;
  }
  .ng-arrow-wrapper {
    display: none;
  }
  border: 1px $color-tide solid !important;
}
.custom-theme .ng-select-container:focus {
  border: 1px solid #66afe9 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}
.custom-theme.ng-select-focused {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  border-radius: 4px !important;
}

// apply theme to the tags
.custom-theme .ng-value {
  background: $custom-primary !important;
  color: $custom-primary-font !important;
  border-radius: 4px !important;
  height: 28px;
  line-height: 26px;
}

// apply theme to the delete icon
.custom-theme .ng-value .ng-value-icon {
  fill: #fff;
  transition: all 0.35s;
}
.custom-theme .ng-value .ng-value-icon:hover {
  background-color: $custom-primary !important;
  color: $custom-primary-font !important;
}
.ng-select .ng-select-container .ng-value-container .ng-input > input {
  padding: 0px;
}

.external-api-event .ui-table-wrapper {
  overflow-x: unset;
}
body .ck.ck-balloon-panel {
  z-index: 10000;
}
.has-error .ng-select-container,
.has-error .ck-editor__editable {
  border-color: #a94442 !important;
}

.cdk-overlay-container{
  z-index: 1060;
}

// cron editor style starts

html {
  --mat-select-focused-arrow-color: unset;
  --mdc-outlined-text-field-focus-label-text-color: unset;
  --mdc-outlined-text-field-label-text-color: unset;
  --mat-option-selected-state-label-text-color: unset;

  --mdc-checkbox-selected-focus-icon-color: #2196F3;
  --mdc-checkbox-selected-hover-icon-color: #2196F3;
  --mdc-checkbox-selected-icon-color: #2196F3;
  --mdc-checkbox-selected-pressed-icon-color: #2196F3;
  --mdc-checkbox-selected-focus-state-layer-color: unset;
  --mdc-checkbox-selected-hover-state-layer-color: unset;
  --mdc-checkbox-selected-pressed-state-layer-color: unset;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: unset;
  --mdc-radio-selected-hover-icon-color: unset;
  --mdc-radio-selected-icon-color: unset;
  --mdc-radio-selected-pressed-icon-color: unset;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: unset;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: inherit;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: inherit;
  --mat-tab-header-active-ripple-color: inherit;
  --mat-tab-header-inactive-ripple-color: inherit;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: inherit;
  --mat-tab-header-active-hover-label-text-color: inherit;
  --mat-tab-header-active-focus-indicator-color: inherit;
  --mat-tab-header-active-hover-indicator-color: inherit;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, 
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: unset;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline{
  border-color: $theme-color-separator !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
}

.mdc-tab--active {
  font-weight: 600 !important;
}

.mdc-form-field {
  font-family: "Open Sans", sans-serif !important;
  white-space: nowrap;
}

.mdc-radio {
  padding-bottom: 39px !important;
}


mat-tab-header .mat-tab-label {
  min-width: 100px;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: inherit !important;
}


.cron-editor-tab-content {
  height: 170px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  --mdc-outlined-text-field-label-text-size: 14px;
  padding: .6em 0 1em;
  min-height: unset;

  .mat-mdc-select {
    --mat-select-trigger-text-size: 14px;
  }
}

.field-width-100 .mat-mdc-form-field-infix {
  width: 100px;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-form-field-outlined-label-text-populated-size: 14px;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0,0,0,.12);
}

// corn editor style ends


@keyframes pos-spinner-icon_custom-progress-spinner-color {
  100%,
  0% {
    stroke: $theme-color-separator;
  }
  50% {
    stroke: $theme-color-primary;
  }
}

@keyframes pos-spinner-icon_custom-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
