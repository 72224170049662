﻿@import "../../shared/mixins";
@import "../../shared/colors";

.loader {
    top: 48%;
    left: 48%;
    width: 50px;
    height: 50px;
    position: absolute;
}

.loader-div {
    height: 100%;
    text-align: center;
}

.loading-block {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $color-black-0-6;
    z-index: 10005;
}

.order-loading-block {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $color-black-0-6;
    z-index: 10000;
}
