﻿@import "../../vars/colors";
@import "../../vars/fonts";


.edit-form {

    &__section {
        background: $color-white none repeat scroll 0 0;
        border: 1px solid $color-grey-light;
        margin-bottom: 10px;
        padding: 0 15px 10px;

        &__heading {
            border-bottom: 1px solid $color-grey-light;
            font-size: $font-title;
            font-weight: 600;
            margin: 0 -15px;
            // sass-lint:disable no-important
            padding: 10px 15px !important;
            background: $color-grey-light-0 none repeat scroll 0 0;
        }

        &__label {
            padding-top: 7px;
        }

        &__file-input {
            // sass-lint:disable no-important
            display: inline-block !important;
            width: 75%;
            margin-right: 3%;
        }

        &__file-img {
            border: 1px solid $color-silver;
            display: inline-block;
            height: auto;
            max-width: 20%;
            padding: 1px;
            vertical-align: middle;
        }
    }
}

.design-edit-form {
    border: solid 1px $color-grey-light;
    padding: 0;

    &__section {
        padding: 0;

        &__field {
            padding-top: 10px;

            &__text-area {
                height: 111px;
            }
        }

        &__footer {
            padding: 0;

            &__controls {
                border: solid 1px $color-grey-light;
                text-align: center;
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }
    }
}
