﻿@import "../vars/colors";
@import "../vars/fonts";
@import "../shared/mixins";

.navbar-default {
    @include mobile-all-large() {
        box-shadow: none;
    }

    background: none;

    .navbar-nav {
        > li {
            > a {
                color: $color-white;
            }
        }
    }

    .navbar-collapse {
        border: none;
        overflow: hidden;
    }
}

.navbar-toggle {
    @include tablet() {
        margin-top: 28px;
    }

    @include mobile-641-768() {
        margin-top: 30px;
    }

    @include mobile-all-range() {
        margin-top: 30px;
    }

    background: none;
    border: none;
    margin-right: 0;
    margin-top: 17px;

    &:hover,
    &:focus {
        // sass-lint:disable no-important
        background: none !important;
    }

    .icon-bar {
        // sass-lint:disable no-important
        background: $color-white !important;
    }
}

.tab-content {
    > .tab-pane {
        width: 83%;
        float: right;
        background-color: $color-white;
        padding-top: 10px;  
        padding-bottom: 10px;        
        border-radius: 15px;
        margin-top: 10px;
    }
}

.vertical-tabs {

    .nav {
        > li {
            > a {
                background-color: $color-grey-light-0;
                text-decoration: none;
                display: block;
                padding: 10px 15px;
            }
        }
    }

    .nav-stacked>li+li {
        margin-top: 2px;
        margin-left: 0;
    }

    .nav-pills {
        float: left;
        width: 20%;
        padding: 0 20px 0 0;

        .active {
            > a {
                background-color: $color-sky-blue-dark;
                color: $color-white;
                
                &:hover,
                &:focus {
                    background-color: $color-sky-blue-dark;
                    color: $color-white;
                }
            }
        }

        >li > a {
            border-radius: 4px;
            color: $color-orange-extreme;
        }

        @include mobile-upto-640() {
            float: left;
            margin-top: 10px;
            padding: 0;
            text-align: center;
            width: 100%;
        }
    }

    .nav-stacked {
        margin: 10px 0 0;
        padding: 0 20px 20px 10px;
    }

    .tab-head {
        text-align: center;
        padding-top: 5px;
        font-size: $font-medium;
        padding-bottom: 5px;
    }

    .tab-content {
        @include mobile-upto-640() {
            float: right;
            width: 100%;
        }

        float: right;
        width: 80%;
    }
}
