﻿$color-green: green;
$color-green-light: #78ea30;
$color-green-light-x: #37fa41;
$color-green-dark: #499b98;
$color-light-sea-green: #78ea30;
$color-bright-green: #61ff00;
$color-light-green: #d2e9d5;

$color-orange: #ff790c;
$color-warning-light-orange: #e38d13;
$color-orange-dark: #ff943d;
$color-orange-extreme: #ea5831;

$color-gold: #ffd700;

$color-lightsalmon: lightsalmon;
$color-gun-powder: #444455;
$color-grey: grey;
$color-grey-light: lightgrey;
$color-grey-light-0: #eee;
$color-grey-dark: #5f6062;
$color-grey-extreme: #4c4c4c;
$color-grey-80: #ccc;
$color-grey-90: #e5e5e5;
$color-grey-light-1: #aba3a3;
$color-grey-alto: #dddddd;
$color-grey-dark-6: #666666;
$color-grey-9: #999999;


$color-yellow: yellow;
$color-black: black;
$color-white: white;
$color-antiquewhite: antiquewhite;

$color-blue: blue;
$color-sky-blue: #54a4de;
$color-sky-blue-dark: #428bca;
$color-lightblue: lightblue;
$color-deepskyblue: deepskyblue;
$color-blueviolet: blueviolet;
$color-portage: #898df5;
$color-aliceblue: aliceblue;
$color-blue-active: #007ad9;

$color-red: red;
$color-red-dark: #ef5c5c;
$color-red-extreme: #a94442;
$color-maroon: marron;

$color-lightred: #ffcdd2;
$color-lightorange: #ffe2c4;
$color-lightblue: #bbdefb;

$color-cyan: #0cf6fa;
$color-cyan-dark: #0ce2fa;

$color-olivine: #a5b878;
$color-disabled: #ddd;
$color-cocoa-brown: #221919;
$color-monte-carlo: #7accc9;
$color-geyser: #dae1e3;
$color-wild-sand: #f4f4f4;

$color-varden: #fff8dc;
$color-tena: #e0dcbf;
$color-alabaster: #f9f9f9;
$color-silver: #c4c4c4;
$color-min-shaft: #333333;
$color-neptune: #7abab9;
$color-sinbad: #9acccb;
$color-fair-pink: #ffeeee;
$color-lemonchiffon: lemonchiffon;
$color-black-0-6: rgba(0, 0, 0, 0.6);
$color-tide: #b3b2ae;
$color-mine-shaft: #232323;
$color-island-spice: #fffdf0;
$color-dark-violet: darkviolet;
$color-medium-violet: mediumvioletred;
$color-electric-violet: #e400f7;
$color-davs-gray: #555;
$color-lightgray: #d3d3d3;
$color-success: #28a745;
$color-warning: #ffc107;
$color-green-light: #0efe28;
$color-orange-light_1: #f7d370;

//FontAwesome Icon Colors
$color-add: #79ea31;
$color-red-trash: #c72b0c;
$color-red-invoice-trash: #ff0000;
$color-print: #3fa5d2;
$color-ban: #ea5831;
$color-image: #337ab7;

// Kiosk Colors
$kiosk-primary-color: #509950;

//Theme Colors
$theme-color-primary: var(--branding-header-color, "#7accc9");
$theme-font-color-primary: var(--branding-header-font-color, "#000000");
$theme-color-separator: var(--branding-header-separator-color, "#ff790c");
$theme-font-color-separator: var(--branding-header-separator-font-color, "#ffffff");
