@import "../shared/colors";
@import "../shared/mixins";
@import "../vars/fonts";
@import "../vars/font-family";

.management-console-page {
    .popover-content {
        width: 250px;
    }

    .right-side-bar {
        border: solid 1px $color-grey-light;

        .right-side-bar__info {
            border-bottom: 1px dashed $color-grey-light;
            color: $color-black;
            float: left;
            font-size: $font-normal;
            font-weight: normal;
            margin-bottom: 19px;
            padding-bottom: 9px;
            width: 100%;

            .right-side-bar__info__heading {
                border: medium none;
                color: $color-black;
                float: left;
                font-family: $family-open-sans;
                font-size: $font-medium;
                font-weight: bold;
                padding-bottom: 6px;
                padding-top: 0;
                text-align: center;
                width: 100%;
            }


            .right-side-bar__info__matrix {
                color: $color-black;
                float: left;
                font-size: $font-normal;
                font-weight: normal;
                padding: 7px 0;
                width: 100%;

                .right-side-bar__info__matrix__name {
                    color: $color-black;
                    float: left;
                    font-size: $font-normal;
                    font-weight: normal;
                    padding-bottom: 2px;
                    width: 100%;
                }
            }

            last-child {
                border: medium none;
            }
        }
    }

    .manage-console-item {
        text-align: center;
        cursor: pointer;
        padding: 20px 0;
        height: 100px;
        width: 150px;
        &:hover {
            background-color: $color-grey-light;
        }

        img {
            height: 48px;
        }
    }

    .manage-console-item-disabled {
        text-align: center;
        padding: 20px 0;
        height: 100px;
        width: 150px;
        margin: auto;
        background-color: $color-grey-light-0;

        @media (max-width: 767px) {
            width: 100px;
            margin-top: 5px;
        }
    }

    .type-amount {
        @include desktop() {
            min-height: 76px;
        }

        @include desktop-large() {
            font-size: $font-90;
            min-height: 120px;
        }

        text-align: right;
        min-height: 90px;
        font-size: $font-cal-amount;
        color: $color-black;
        font-weight: bold;
    }

    .img-arrow {
        width: 28%; 
    }
}

.sales-product-footer {
    position: absolute;
    bottom: 0;
    padding: 10px;
    background: $color-white;
}
