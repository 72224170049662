@import "../vars/fonts";
@import "../vars/colors";

.make-table-page {
    overflow-x: hidden;

    &__content {
        padding: 10px;
        background-color: $color-grey;
        width: 100%;

        &__block {
            position: relative;
            padding: 0;

            &__update {
                opacity: 0.8;
                position: absolute;
                z-index: 14;
                display: block;
                width: 100%;
                font-weight: 900;
                text-align: right;
                color: $color-white;
                background-color:  var(--bs-btn-bg);
            }

            &__qty {
                border-radius: 49%;
                font-weight: normal;
            }

            &__icon {
                float: right;
                width: 48px;
                margin-bottom: 1px;

                &--i {
                    float: right;
                    padding-right: 5px;
                }
            }

            &__info {
                font-size: $font-x-normal;
                margin-left: 10px;
                margin-right: 5px;
            }
        }
    }

    &__no-active-order {
        z-index: 15;
        color: $color-red;
        height: -webkit-fill-available;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    &__order-update {
        opacity: 0.5;
        z-index: 2;
        position: absolute;
        background-color: $color-cyan;
        width: 100%;
        height: 100%;
        color: $color-red;
        font-weight: bold;
        text-align: center;
    }
}

.qty-circle {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 1px;
}

.scroll-btn {
    opacity: 0.7;
    position: fixed;
    right: 1%;
    margin-left: -16px;
    display: block;
    width: 40px;
    height: 40px;
    border: 2px solid $color-black;
    background-size: 14px auto;
    border-radius: 50%;
    z-index: 1050;
    animation: bounce 2s infinite 2s;
    transition: 0.2s ease-in;
    cursor: pointer;

    &::before {
        position: absolute;
        display: block;
        width: 12px;
        height: 12px;
        content: "";
        border: 2px solid $color-black;
        border-width: 0 0 2px 2px;
    }
}


.scroll-down {
    bottom: 60px;

    &::before {
        top: calc(50% - 8px);
        left: calc(50% - 6px);
        transform: rotate(-45deg);
    }
}

.scroll-up {
    top: 15%;

    &::before {
        top: calc(50% - 4px);
        right: calc(50% - 6px);
        transform: rotate(135deg);
    }
}

@keyframes bounce {
    0%,
    100%,
    20%,
    50%,
    80% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}

.hidden-visibility {
    visibility: hidden;
}

.btn-updated {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;   
    font-size: $font-legend;
    text-align: center;
    opacity: 0.8;
    position: fixed;
    z-index: 14;
    right: 0;
    top: 28%;	
    transform: rotate(270deg);
    transform-origin: right bottom 0;	
    width: 12%;
    background: $theme-color-separator;
}

.maketable-box {
    border-left: 2px solid $theme-color-separator;
    top: 80px;
    height: inherit;
    padding: 5px;
    overflow: auto;
    position: fixed;
    right: 0;
    bottom: 0; 
    background: $color-white;
    width: 25%;

    &__icon {
        float: right;
        width: 25px;

        &--i {
            float: right;
            padding-top: 2px;
            padding-right: 3px;
        }
    }
}

.group-name {
    font-weight: 700;
    font-size: $font-medium;
}
.order-number-circle {
    margin-top: 3px;
    float: right;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 2px;
}
.maketable-view-dropdown {
    top: 0;
    left: 42%;
    //max-width: 200px;
    min-width: 180px;
}
.right45 {
    right: 45px;
}

.order-bottom-clock {
    .dropdown-toggle::after {
        display:none;
    }
}

.setting-icon-size {
    font-size: $font-cal-keypad;
    $enable-caret: false;
}

.maketable-dot {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
  }
