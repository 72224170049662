﻿@import "mixins";

// margins & paddings
.top-1 {
    top: 1px;
}

.top-2 {
    top: 2px;
}

.top-n8 {
    top: -8px;
}

.top-n30 {
    top: -30px;
}

.m-auto {
    margin: auto;
}

.ml-12 {
    margin-left: 12px;
}

.mi-0 {
    margin: 0 !important;
}

.mbi-2 {
    // sass-lint:disable no-important
    margin-bottom: 2px !important;
    // sass-lint:enable no-important
}

.mbi-0 {
    margin-bottom: 0;
}

.mti-25 {
    // sass-lint:disable no-important
    margin-top: 25px !important;
    // sass-lint:enable no-important
}

.mti-15 {
    margin-top: 15px !important;
}

.mti-10 {
    margin-top: 10px !important;
}

.mti-0 {
    margin-top: 0 !important;
}

.mt-sm-15 {
    @media (max-width: 992px) {
        margin-top: 15px;
    }
}

.mt-sm-10 {
    @media (max-width: 992px) {
        margin-top: 10px;
    }
}

.mb-xs-10 {
    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
}

.mli-0 {
    // sass-lint:disable no-important
    margin-left: 0 !important;
    // sass-lint:enable no-important
}
.mri-0 {
    // sass-lint:disable no-important
    margin-right: 0 !important;
    // sass-lint:enable no-important
}

.mri-10 {
    margin-right: 10px !important;
}

.mri-5 {
    margin-right: 5px !important;
}

.m-0-auto {
    margin: 0 auto;
}

.pi-5 {
    // sass-lint:disable no-important
    padding: 5px !important;
    // sass-lint:enable no-important
}

.pt-15-important {
    // sass-lint:disable no-important
    padding-top: 15px !important;
    // sass-lint:enable no-important
}

.pt-10-important {
    // sass-lint:disable no-important
    padding-top: 10px !important;
    // sass-lint:enable no-important
}

.pti-5 {
    padding-top: 5px !important;
}

.pti-0 {
    // sass-lint:disable no-important
    padding-top: 0 !important;
    // sass-lint:enable no-important
}

.pbi-0 {
    // sass-lint:disable no-important
    padding-bottom: 0 !important;
    // sass-lint:enable no-important
}

.pbi-10 {
    // sass-lint:disable no-important
    padding-bottom: 10px !important;
    // sass-lint:enable no-important
}

.pli-38 {
    // sass-lint:disable no-important
    padding-left: 38px !important;
    // sass-lint:enable no-important
}

.pli-15 {
    padding-left: 15px !important;
}

.pli-5 {
    // sass-lint:disable no-important
    padding-left: 5px !important;
    // sass-lint:enable no-important
}

.pli-4 {
    padding-left: 4px !important;
}

.pi-0 {
    // sass-lint:disable no-important
    padding: 0 !important;
    // sass-lint:enable no-important
}

.pli-0 {
    // sass-lint:disable no-important
    padding-left: 0 !important;
    // sass-lint:enable no-important
}

.pri-0 {
    // sass-lint:disable no-important
    padding-right: 0 !important;
    // sass-lint:enable no-important
}

.pl-075em {
    // sass-lint:disable no-important
    padding-left: 0.75em !important;
    // sass-lint:enable no-important
}

.pr-075em {
    // sass-lint:disable no-important
    padding-right: 0.75em !important;
    // sass-lint:enable no-important
}

.pe-pix-25 {
    // sass-lint:disable no-important
    padding-right: 25px !important;
    // sass-lint:enable no-important
}

.pr-1-5 {
    padding-right: 1.5px;
}

.pr-custom-pix-sm-0 {
    padding-right: 15px;
    @media only screen and (max-width: 990px) {
        padding-right: 5px;
    }

    @media only screen and (max-width: 767px) {
        padding-right: 0;
    }
}

.pl-custom-pix-sm-0 {
    padding-left: 15px;
    @media only screen and (max-width: 990px) {
        padding-left: 0;
    }
}

.pt-custom-pix-sm-0 {
    padding-top: 0;
    @media only screen and (max-width: 1280px) {
        padding-top: 10px;
    }
}

.pt-custom-pix-lg-10 {
    padding-top: 15px;
    @include desktop-large() {
        padding-top: 10px;
    }
}

.col-p {
    @media (max-width: 1160px) {
        padding: 5px;
    }

    @include mobile-all-large() {
        padding: 2px;
    }

    padding-top: 10px;
}

.l-0 {
    left: 0;
}

.r-0 {
    right: 0;
}

.r-2 {
    right: 2px;
}

.r-10 {
    right: 10px;
}

.r-20 {
    right: 20px;
}

.t-0 {
    top: 0;
}

.tl-10 {
    top: 10px;
    left: 10px;
}

.t-40i {
    // sass-lint:disable no-important
    top: 40px !important;
    // sass-lint:enabled no-important
}

.tr-10 {
    top: 10px;
    right: 10px;
}

.bl-10 {
    bottom: 10px;
    left: 10px;
}

.br-10 {
    bottom: 10px;
    right: 10px;
}
.r-40 {
    right: 40px;
}

.h-fit-content {
    height: fit-content;
}

//width in %
.width-full {
    width: 100%;
}

.w-100 {
    width: 100%;
}

.width-98 {
    width: 98%;
}

.width-95 {
    width: 95%;
}

.width-90 {
    width: 90%;
}

.width-85 {
    width: 85%;
}


.list-width-80 {
    width: 80%;
}

.list-width-70 {
    width: 70%;
}

@media (max-width: 768px) {
    .list-width-70,
    .list-width-80 {
        width: 96%;
    }
}

.width-80 {
    width: 80%;
    max-width: 80%;

    &.menu-explorer-product-info {
        @media (max-width: 1300px) {
            width: 95%;
            max-width: 95%;
        }
    }

    @media (max-width: 767px) {
        width: 98%;
        max-width: 98%;
    }
}

.width-75 {
    width: 75%;
}

.width-70 {
    width: 70%;
}

.width-65 {
    width: 65%;
    max-width: 65%;
}

.width-50 {
    width: 50%;
}

.width-20 {
    width: 20%;
}

.width-15 {
    width: 15%;
}

.width-12-5 {
    width: 12.5%;
}

.width-10 {
    width: 10%;
}

.w-pix-130 {
    width: 130px;
}

.min-w-pix-130 {
    min-width: 130px;
}

.min-w-pix-90 {
    min-width: 90px;
}

.max-w-pix-200 {
    max-width: 200px;
}

.w-pix-15 {
    width: 15px;
}

.w-max-content {
    width: max-content;
}

.modal-max-width-100 {
    --bs-modal-width: 100%;
}

.modal-max-width-98 {
    --bs-modal-width: 98%;
}

.modal-max-width-95 {
    --bs-modal-width: 95%;
}

.modal-max-width-80 {
    --bs-modal-width: 95%;

    &.menu-explorer-product-info {
        @media (max-width: 1300px) {
            --bs-modal-width: 95%;
        }
    }
}

.modal-max-width-75 {
    --bs-modal-width: 75%;
}

.modal-max-width-70 {
    --bs-modal-width: 70%;
}

.modal-max-width-65 {
    --bs-modal-width: 65%;
}

.modal-max-width-55 {
    --bs-modal-width: 55%;
}

.modal-max-width-40 {
    --bs-modal-width: 40%;
}

.modal-max-width-20 {
    --bs-modal-width: 20%;
}

.modal-max-width-lg-35 {
    @include desktop-large() {
        max-width: 35%;
    }
}

.w-pix-115 {
    width: 115px;
}

.width-30-px {
    width: 30px;
}

.width-inherit {
    width: inherit !important;
}

.modal-width {
    @include desktop() {
        width: 65%;
    }

    @include desktop-small-all() {
        width: 70%;
    }

    @include desktop-small() {
        width: 77%;
    }

    @include mobi-tab() {
        width: 90%;
    }

    width: 48%;
}

.sm-modal-width {
    @include desktop() {
        width: 30%;
    }

    @include desktop-small-all() {
        width: 40%;
    }

    @include tablet() {
        width: 45%;
    }

    @include mobile-all-large() {
        width: 70%;
    }

    @include mobile-all-range() {
        width: 90%;
    }

    width: 25%;
}

//height in px

.hg-20 {
    height: 20px;
}

//width in px
.col-0 {
    width: 0;
}

.col-w-10 {
    width: 10px;
}

.col-15-vw {
    width: 15vw;
}

.col-16 {
    width: 16px;
}

.col-18 {
    width: 18px;
}

.col-20 {
    width: 20px;
}

.col-25-vw {
    width: 25vw;
}

.col-25 {
    width: 25px;
}

.col-28 {
    width: 28px;
}

.col-30 {
    width: 30px;
}

.col-33 {
    width: 33px;
}

.col-35 {
    width: 35px;
}

.col-37 {
    width: 37px;
}

.col-40 {
    width: 40px;
}

.col-50 {
    width: 50px;
}

.col-52 {
    width: 52px;
}

.col-60 {
    width: 60px;
}

.col-65 {
    width: 65px;
}

.col-70 {
    width: 70px;
}

.col-75 {
    width: 75px;
}

.col-85 {
    width: 85px;
}

.col-90 {
    width: 90px;
}

.col-95-vw {
    width: 95vw;
}

.col-100 {
    width: 100px;
}

.col-110 {
    width: 110px;
}

.mcol-100 {
    max-width: 100px;
}

.min-col-50 {
    min-width: 50px;
}

.min-col-70 {
    min-width: 70px;
}

.min-col-130 {
    min-width: 130px;
}

.mcol-150 {
    max-width: 150px;
}

.max-width-none {
    max-width: none !important;
}

.col-135 {
    width: 135px;
}

.col-120 {
    width: 120px;
}

.max-col-120 {
    max-width: 120px;
}

.col-121 {
    width: 121px;
}

.mcol-130 {
    min-width: 130px;
}

.col-130 {
    width: 130px;
}

.col-131 {
    width: 131px;
}

.col-140 {
    width: 140px;
}

.col-150 {
    width: 150px;
}

.col-160 {
    width: 160px;
}

.col-170 {
    width: 170px;
}

.col-178 {
    width: 178px;
}

.col-180 {
    width: 180px;
}

.col-185 {
    width: 185px;
}

.col-200 {
    width: 200px;
}

.col-220 {
    width: 220px;
}

.col-225 {
    width: 225px;
}

.col-230 {
    width: 230px;
}

.col-240 {
    width: 240px;
}

.col-250 {
    width: 250px;
}

.col-255 {
    width: 255px;
}

.col-275 {
    width: 275px;
}

.col-300 {
    width: 300px;
}

.col-320 {
    width: 320px;
}

.col-350 {
    width: 350px;
}

.col-370 {
    width: 370px;
}

.col-400 {
    width: 400px;
}

.col-450 {
    width: 450px;
}

.col-560 {
    width: 560px;
}

.height-fill-available {
    height: -webkit-fill-available;
}

.height-100vh {
    height: 100vh;
}

.height-93 {
    height: 93%;
}

.h-80 {
    height: 80px;
}

.h-100 {
    height: 100px;
}

.h-150 {
    height: 150px;
}

.h-170 {
    height: 170px;
}

.h-200 {
    height: 200px;
}

.h-220 {
    height: 220px;
}

.h-245 {
    height: 245px;
}

.h-300 {
    height: 300px;
}

.h-350 {
    height: 350px;
}

.pt-50p {
    padding-top: 50%;
}

.h-60 {
    height: 60px;
}

.h-50 {
    height: 50px;
}

.h-20 {
    height: 20px;
}

.h-17 {
    height: 17px;
}

.h-36 {
    height: 36px;
}

.h-38 {
    height: 38px;
}

.h-40 {
    height: 40px;
}

.h-48 {
    height: 48px;
}

.t-3 {
    top: 3px;
}

.w-fit-content{
    width: fit-content;
}

.fit-content {
    width: fit-content !important;
}

.table-height {
    @media (max-height: 650px) {
        height: 200px;
    }

    @media (max-height: 600px) {
        height: 140px;
    }

    @media (max-height: 550px) {
        height: 130px;
    }

    @media (max-height: 400px) {
        height: 100px;
    }

    height: 300px;
}

.lh-15 {
    line-height: 15px;
}
