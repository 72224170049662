$font-14: 14px;
$font-family: "Open Sans", sans-serif;

.p-inputtext,
.p-component {
  font-size: $font-14;
  font-family: $font-family !important;

  .pi-chip-remove-icon {
    padding-top: 2px !important;
  }
}
.p-datatable {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .p-datatable-header {
    flex: 1;
    padding: 0.7rem 0 !important;
    .p-inputgroup .p-inputtext {
      padding: 6px 12px;
      background-color: #fff;
      font-size: $font-14;
      font-weight: normal;
      //color: #555;
      //line-height: 1.2;
      width: unset;
      font-family: $font-family;
    }
  }

  p-paginator {
    margin-left: auto;
  }

  .p-datatable-header,
  .p-paginator {
    background: white !important;
    border: none !important;
  }
  .p-datatable-wrapper,
  .p-datatable-scrollable-wrapper {
    min-height: 0.01%;
    overflow-x: auto;
    width: 100%;
    @media all and (min-width: 320px) and (max-width: 599px) {
      table {
        table-layout: auto;
      }
    }
    @media all and (min-width: 600px) and (max-width: 1150px) {
      table {
        display: block;
      }
    }
    @media all and (min-width: 600px) and (max-width: 1920px) {
      table {
        table-layout: fixed;
      }
    }
    table {
      border: none;
      width: 100%;
      max-width: 100%;
      font-size: $font-14;
      font-family: $font-family;
      .table-header,
      .p-datatable-thead {
        background-color: #f9f9f9;
        th:not(.table-drag-column) {
          width: 20%;
        }
        th {
          background: #f9f9f9 !important;
          color: #ea5831 !important;
          border: 1px solid #ddd;
          padding: 8px;
          line-height: 1.42857143;
          vertical-align: top;
        }
        .p-sortable-column-icon {
          color: #ea5831;
          margin-left: 0;
        }
        th:focus,
        th:hover,
        .pi-sort:hover,
        .pi-sort-up,
        .pi-sort-down {
          color: #23527c !important;
          .p-sortable-column-icon {
            color: #23527c !important;
          }
        }
        th:hover:not(.p-sortable-column) {
          color: #ea5831 !important;
        }
        .cell-edit {
          border-top: 1px solid #ddd;
          border-left: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          border-right: none;
          padding-right: 0%;
          text-align: center;
        }
        .cell-delete {
          border-top: 1px solid #ddd;
          border-left: none;
          border-bottom: 1px solid #ddd;
          border-right: none;
        }
        th:last-child:not(.cell-link) {
          border-right: 1px solid #ddd;
        }
        .cell-link {
          border-top: 1px solid #ddd;
          border-left: none;
          border-bottom: 1px solid #ddd;
          border-right: none;
        }
        tr th:last-child {
          border-right: 1px solid #ddd;
        }
        tr .inbox-msg {
          border-right: none;
        }
        tr .action-header {
          padding-left: 3px;
        }
      }
      .p-datatable-tbody {
        > tr {
          background: #ffffff;
        }

        > tr:nth-child(even) {
          background-color: #fafafb;
        }
        tr td {
          border: 1px solid #ddd;
          padding: 8px;
          line-height: 1.42857143;
          vertical-align: top;
          color: grey;
          .p-datatable-reorderablerow-handle {
            cursor: move;
          }
          .param-badge {
            color: green;
            background: transparent;
            font-size: $font-14;
          }
        }
        .cell-edit {
          border-top: 1px solid #ddd;
          border-left: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          border-right: none;
          padding-right: 0%;
        }
        .cell-delete {
          border-top: 1px solid #ddd;
          border-left: none;
          border-bottom: 1px solid #ddd;
          border-right: none;
        }
        tr td:last-child:not(.cell-link) {
          border-right: 1px solid #ddd;
        }
        .cell-link {
          border-top: 1px solid #ddd;
          border-left: none;
          border-bottom: 1px solid #ddd;
          border-right: none;
          padding-left: 0%;
          .fa-copy,
          .fa-cog {
            font-size: 1.17em;
          }
        }
        tr td:last-child {
          border-right: 1px solid #ddd;
        }
        tr .inbox-msg {
          border-right: none;
          padding-right: 0%;
        }
        tr .greyicon {
          a {
            color: grey !important;
          }
          .action-btn {
            i {
              font-size: 1.4em !important;
            }
          }
          a:focus,
          a:hover {
            color: #23527c !important;
            cursor: pointer;
          }
        }
        tr .training-gear-icon {
          .action-btn {
            i {
              font-size: 1.2em !important;
            }
          }
        }
        tr .p-editing-cell {
          .p-chkbox-box.p-state-active {
            background: #186ba0 !important;
          }
        }
      }
    }
  }

  .p-paginator {
    padding-right: 0;
    .p-state-focus {
      background: #ffffff !important;
    }
    .p-state-default {
      background-color: #ffffff;
      font-weight: normal;
      color: #337ab7;
    }
    .p-paginator-pages {
      //height: 2.2em;
      .p-paginator-page {
        line-height: 2em;
      }
    }
    button:not(.p-state-disabled):not(.p-state-active):hover {
      color: #23527c;
    }
    .p-dropdown {
      //height: 2.2em;
      font-family: "Open Sans", sans-serif;
      .p-dropdown-label {
        color: #23527c !important;
      }
    }
  }
  .p-paginator .p-paginator-page,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last,
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev {
    //height: 2.2em;
    font-family: "Open Sans", sans-serif;
  }

  .bg-theme-primary {
    td {
      color: $theme-font-color-primary !important;
    }
  }
}

.p-dropdown,
.p-dropdown-panel,
.p-autocomplete,
.p-autocomplete-panel {
  min-width: 4.6em;
  .p-dropdown-items-wrapper,
  .p-autocomplete-items {
    .p-state-highlight {
      border-color: #156090 !important;
      background-color: #186ba0 !important;
      color: #ffffff !important;
    }
    .p-dropdown-item:not(.p-state-highlight):not(.p-state-disabled):hover,
    .p-dropdown-item:not(.p-state-highlight):not(.p-state-disabled),
    .p-autocomplete-list-item:not(.p-state-highlight):not(.p-state-disabled):hover,
    .p-autocomplete-list-item:not(.p-state-highlight):not(.p-state-disabled) {
      color: #23527c !important;
    }
  }
}

.p-accordion-content {
  display: block !important;
}

body
  .p-accordion
  .p-accordion-header-link:not(.p-state-disabled).p-state-active
  a {
  background-color: #007ad9;
  border: 1px solid #007ad9;
  color: #ffffff;
}

body .p-accordion .p-accordion-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
}

body .p-accordion .p-accordion-header {
  margin-bottom: 2px;
}

p-calendar {
  &.ng-dirty {
    // sass-lint:disable no-important
    &.ng-invalid > .ui-calendar > .ui-inputtext {
      border-bottom-color: $color-grey-light !important;
    }
    &.ng-invalid {
      &.has-error > .ui-calendar > .ui-inputtext {
        border-bottom-color: $color-red-extreme !important;
        &:focus,
        &:hover {
          border-bottom-color: $color-red-extreme !important;
        }
      }
    }
    // sass-lint:enable no-important
  }
  .ui-calendar {
    .ui-button {
      // sass-lint:disable no-important
      border-color: $color-grey-80 !important;
      background: $color-grey-90 !important;
      color: $color-mine-shaft !important;

      &:focus,
      &:hover {
        background-color: $color-grey-light !important;
      }
      // sass-lint:enable no-important
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-size: $font-14;
}

.p-checkbox-label {
  line-height: inherit;
}
